import {
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   FormControl,
   InputLabel,
   MenuItem,
   Select,
   TextField,
   Typography,
} from "@mui/material";
import React, { useState } from "react";
import { createLabels } from "../../../services/labelServide";
import { useDispatch } from "react-redux";
import { getAllLabels } from "../../../store/slices/labelSlice";
import { SketchPicker } from "react-color";

function AddLabel({ openAddLabel, setOpenAddLabel }) {
   const dispatch = useDispatch();
   const [labelName, setLabelName] = useState("");
   const [selectedColor, setSelectedColor] = useState("");

   const handlechange = (e) => setLabelName(e.target.value);
   const handleClose = () => setOpenAddLabel(false);

   const handleSave = async () => {
      const response = await createLabels(labelName,selectedColor);

      if (response && response.status === "success") {
         setOpenAddLabel(false);
         dispatch(getAllLabels());
      }
   };
   const handleChangeColor = (color) => {
      setSelectedColor(color.hex);
   };
   return (
      <Dialog maxWidth="sm" open={openAddLabel} onClose={handleClose}>
         <DialogTitle>Assign Label</DialogTitle>
         <DialogContent>
            <DialogContentText>
               You can assign a labe for this contact to catogorize.
            </DialogContentText>
            <Box
               noValidate
               component="form"
               sx={{
                  display: "flex",
                  flexDirection: "column",
                  m: "auto",
                  width: "100%",
               }}
            >
               <FormControl sx={{ mt: 2 }} fullWidth>
                  <TextField
                     label="Label Name"
                     id="max-width"
                     value={labelName}
                     onChange={handlechange}
                  />
               </FormControl>
               <Typography mt={1}>Color</Typography>
               <Box
                  height={20}
                  width={150}
                  sx={{ backgroundColor: selectedColor }}
               >
                  <span style={{color:"#fff",fontSize:"14px"}}>{selectedColor}</span>
               </Box>
               <Box mt={2} sx={{ display: "flex", justifyContent: "center" }}>
                  <SketchPicker
                     color={selectedColor}
                     onChangeComplete={handleChangeColor}
                  />
               </Box>
            </Box>
         </DialogContent>
         <DialogActions>
            <Button variant="outlined" sx={{color:"black",borderColor:"black"}} onClick={handleClose}>Close</Button>
            <Button variant="contained" sx={{backgroundColor:"black",color:"white"}} onClick={handleSave}>Save</Button>
         </DialogActions>
      </Dialog>
   );
}

export default AddLabel;
