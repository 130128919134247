import React, { useEffect, useRef, useState } from "react";
import MicNoneIcon from "@mui/icons-material/MicNone";
import SendIcon from "@mui/icons-material/Send";
import { Box, Button } from "@mui/material";
import { useAudioRecorder, AudioRecorder } from "react-audio-voice-recorder";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import API from "../../../config/axios";

function AudioRecorderSection({ started, setStarted, selectedChat }) {
   const [url, setUrl] = useState("");
   const [recordCompleted, setRecordCompleted] = useState(false);
   const [blob, setBlob] = useState("");

   const convertMIMEType = (blob, newMimeType) => {
      return new Promise((resolve, reject) => {
         const reader = new FileReader();
         reader.onload = () => {
            const arrayBuffer = reader.result;
            const newBlob = new Blob([arrayBuffer], { type: newMimeType });
            resolve(newBlob);
         };
         reader.onerror = (error) => {
            reject(error);
         };
         reader.readAsArrayBuffer(blob);
      });
   };

   const handleSendAudio = async () => {
      try {
         const formData = new FormData();
         formData.append("type", "audio");
         formData.append("to", selectedChat?.phone_number);
         formData.append("chatId", selectedChat?._id);

         let convertedBlob = await convertMIMEType(blob, "audio/mpeg");

         //  const audioResponse = await fetch(url);
         //  const audioBlob = await audioResponse.blob();
         formData.append("audio", convertedBlob, "recording.mp3");
         //  formData.get("audio").type = "audio/mpeg";
         const response = await API.post(`/messages/send`, formData);

         if (response.data.status === "success") {
         }
      } catch (err) {
         console.log(err);
      }
   };

   // Function to delete the recording
   const handleDeleteRecord = () => {
      setUrl("");
      // setStarted(false);
      setRecordCompleted(false);
   };

   const recorderControls = useAudioRecorder();
   const rec = recorderControls.isRecording;
   if (rec) setStarted(true);

   const addAudioElement = (blob) => {
      const url = URL.createObjectURL(blob);
      setUrl(url);
      setBlob(blob);

      setRecordCompleted(true);
   };

   return (
      <Box display="flex" width="100%" justifyContent="end">
         <Box
            sx={{
               // backgroundColor: "#99999926",
               color: "#999",
               borderRadius: "0px 15px 15px 0px",
               padding: "10px",
            }}
         >
            {!recordCompleted && (
               <AudioRecorder
                  classes={{ display: "flex", color: "red" }}
                  onRecordingComplete={addAudioElement}
                  recorderControls={recorderControls}
                  showVisualizer={true}
                  downloadFileExtension="audio/mp3" // Change file extension
                  // mimeType="audio/aac"
               />
            )}
            {url && (
               <Box sx={{ display: "flex", alignItems: "center" }}>
                  <DeleteIcon
                     sx={{ cursor: "pointer" }}
                     onClick={() => handleDeleteRecord()}
                  />

                  <audio controls controlsList="nodownload">
                     <source src={url} type="audio/mpeg" />
                  </audio>
               </Box>
            )}
            {/* <MicNoneIcon onClick={startRecording} /> */}
            {/* <audio controls src={recordedUrl} /> */}
            {/* <button >Start Recording</button> */}
            {/* <button onClick={stopRecording}>Stop Recording</button> */}
         </Box>
         {started && (
            <Box
               sx={{
                  width: "40px",
                  heigth: "40px",
                  borderRadius: "0px",
                  backgroundColor: "#00a884",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
               }}
            >
               <SendIcon
                  sx={{ color: "#fff" }}
                  onClick={() => handleSendAudio()}
               />
            </Box>
         )}
      </Box>
   );
}

export default AudioRecorderSection;
