import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import API from "../../config/axios";

export const getAllContacts = createAsyncThunk("contacts/getAllContacts", async () => {
  try {
    const response = await API.get(`/contacts`);
    return response.data?.contacts;
  } catch (err) {
    console.log(err);
  }
});

const initialState = {
  contacts: [],
};
const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getAllContacts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllContacts.fulfilled, (state, action) => {
        state.loading = false;
        state.contacts = action.payload;
      })
      .addCase(getAllContacts.rejected, (state) => {
        state.loading = false;
      }),
});

export default contactsSlice.reducer;
