import {
   Avatar,
   Box,
   Button,
   CircularProgress,
   Tooltip,
   Typography,
   styled,
   useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import ImageMessage from "./components/ImageMessage";
import TextMessage from "./components/TextMessage";
import DocumentMessage from "./components/DocumentMessage";
import ChatBgImage from "../../assets/images/chat.png";
import FileDisplayBox from "./components/FileDisplayBox";
import AudioRecorder from "./chatComponents/AudioRecorder";
import SendIcon from "@mui/icons-material/Send";
import {
   checkChatExpired,
   fileTypes,
   fomatMessageDate,
   getMessageType,
} from "../../constant/helperFunctions";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
   getAllMessagesByChatId,
   loadMoreChatByChatId,
   saveSelectedChat,
   updatePageNumber,
   updateSentMessage,
} from "../../store/slices/messageSlice";
import {
   getAllChats,
   updateNewMessageCount,
} from "../../store/slices/chatSlice";
import toast, { Toaster } from "react-hot-toast";
import AudioMessage from "./components/AudioMessage";
import ChatHeader from "./chatComponents/ChatHeader";
import ChatInput from "./chatComponents/ChatInput";
import ButtonMessage from "./components/ButtonMessage";
import ChatHeaderSkeleton from "../skeltons/ChatHeaderSkeleton";
import AudioRecorderSection from "./components/AudioRecorderSection";
import ListMessage from "./components/InteractiveMessage";
import InteractiveMessage from "./components/InteractiveMessage";
import TemplateMessage from "./components/TemplateMessage";
import API from "../../config/axios";

const ChatBox = styled(Box)({
   height: "100%",
   backgroundColor: "white",
   borderRadius: "15px",
   position: "absolute",
   top: 0,
   left: 0,
   width: "100%",
   display: "flex",
   flexDirection: "column",
   justifyContent: "space-between",
});

const ContentBox = styled(Box)({
   height: "100%",
   width: "100%",
   overflowY: "scroll",
   background: `url(${ChatBgImage})`,
   backgroundSize: "contain",
   backgroundColor: "#efeae2",
});
const LoadingBox = styled(Box)({
   height: "350px",
   width: "100%",
   maxHeight: "350px",
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
});

const LoadMoreButton = styled(Button)({
   backgroundColor: "#d9fdd3",
   textTransform: "capitalize",
   color: "black",
   fontSize: "12px",
   ":hover": {
      backgroundColor: "#d9fdd3",
      color: "black",
   },
});

const DateBox = styled(Box)({
   boxShadow: "0 1px .5px rgba(var(--shadow-rgb), .13)",
   backgroundColor: "#F4F4F6",
   padding: "5px",
   height: "20px",
   display: "flex",
   alignItems: "center",
   borderRadius: "10px",
   marginTop: "10px",
   marginBottom: "10px",
});

function Chat() {
   const dispatch = useDispatch();
   const matches = useMediaQuery("(min-width:900px)");

   const [messageList, setMessageList] = useState([]);
   const [messageType, setMessageType] = useState("");
   const [openContactDetial, setOpenContactDetail] = useState(false);
   const [openTemplate, setOpenTemplate] = useState(false);
   const openContactDetail = () => setOpenContactDetail(true);
   const [textMessage, setTextMessage] = useState("");
   const [files, setFiles] = useState("");
   const [audioUrl, setAudioUrl] = useState("");
   const [started, setStarted] = useState(false);
   const [sendMessageLoading, setSendMessageLoading] = useState(false);
   const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
   const [isChatExpired, setIsChatExpired] = useState(false);
   const [openContactInfo, setOpenContactInfo] = useState(true);

   //Get current page
   const {
      page,
      selectedChat,
      newMessages,
      messages,
      loading,
      totalMessage,
      loadedMessage,
   } = useSelector((state) => state?.messages);

   const { templates } = useSelector((state) => state.template);

   // const lastMessage = messageList[messages.length - 1];
   // const lastMessageTime = moment(lastMessage?.createdAt).format("h:mm a");

   /**
    * Function to change the message status to read when the user scrolled to bottom
    */
   const updateMessageStatusToRead = async () => {
      try {
         const response = await API.get(
            `/messages/change-status/${selectedChat?._id}`
         );

         if (response.data.status === "success") {
            dispatch(getAllChats());
            // dispatch(updateNewMessageCount({chatId:selectedChat?._id,count:0}))
         }
      } catch (err) {
         console.log(err);
      }
   };

   useEffect(() => {
      setMessageList(messages);
   }, [messages]);

   useEffect(() => {
      let messageCount = 0;
      messages.forEach((msg) => {
         messageCount += msg.messages?.length;
      });
      let loadedMessageCount = 0;
      loadedMessage.forEach((msg) => {
         loadedMessageCount += msg.messages?.length;
      });
      const currentMessageCount = messageCount + loadedMessageCount;

      if (totalMessage > currentMessageCount) {
         setShowLoadMoreButton(true);
      } else {
         setShowLoadMoreButton(false);
      }
   }, [loadedMessage, messages]);

   // ==== Set the chat to last message ===
   const contentBoxRef = useRef(null);

   //Function for scrolling
   const scrollToBottom = () => {
      if (contentBoxRef.current) {
         contentBoxRef.current.scrollTop = contentBoxRef.current.scrollHeight;
      }
   };

   useEffect(() => {
      setTextMessage("");
      setOpenTemplate(false);
      const handleScroll = () => {
         const container = contentBoxRef.current;
         if (container) {
            const { scrollTop, scrollHeight, clientHeight } = container;
            if (scrollTop + clientHeight >= scrollHeight) {
               // If user scrolled to bottom call the function to change the status
               updateMessageStatusToRead();

               // Add your notification logic here
            }
         }
      };
      setIsChatExpired(checkChatExpired(selectedChat));

      const container = contentBoxRef.current;
      if (container) {
         container.addEventListener("scroll", handleScroll);
      }

      // Cleanup the event listener on component unmount
      return () => {
         if (container) {
            container.removeEventListener("scroll", handleScroll);
         }
      };
   }, [selectedChat]);

   useEffect(() => {
      if (newMessages?.messages?.length > 0) {
         const lastNumber = newMessages.messages?.length - 1;
         const lastMsg = newMessages.messages[lastNumber];
         if (lastMsg.flag === "outgoing") {

            scrollToBottom();
         }
      }
   }, [newMessages]);

   useEffect(() => {
      scrollToBottom();
   }, [messageList]);

   const fileuploadRef = useRef(null);
   // function to handle the text message
   const handleChange = (e) => {
      const newText = e.target.value;
      setTextMessage(newText);
   };

   // FUNCTION TO SEND MESSAGES
   const handleSendMessage = async () => {
      let formData = new FormData(); // Initialize FormData object
      let currentMessageType = "";

      // Determine message type
      if (files) {
         currentMessageType = getMessageType(files);
      } else if (textMessage) {
         currentMessageType = "text";
      }
      // Append common form data

      formData.append("type", currentMessageType);
      formData.append("to", selectedChat.phone_number);
      formData.append("chatId", selectedChat._id);

      // Append specific form data based on message type
      switch (currentMessageType) {
         case "text":
            formData.append("text", textMessage);
            break;
         case "image":
            formData.append("file", files, files.name);
            if (textMessage) {
               formData.append("caption", textMessage);
            }
            break;
         case "audio":
            formData.append("file", files,files.name);
            break;
         case "document":
            formData.append("file", files, files.name);
            if (textMessage) {
               formData.append("caption", textMessage);
            }
            break;
         case "video":
            formData.append("file", files, files.name);
            if (textMessage) {
               formData.append("caption", textMessage);
            }
            break;
         case "excel":
            formData.append("file", files, files.name);
            if (textMessage) {
               formData.append("caption", textMessage);
            }
            break;
         default:
            console.error("Unsupported message type");
            return;
      }
      try {
         setSendMessageLoading(true);
         const response = await API.post(`/messages/send`, formData);
         if (response.data.status === "success") {
            setIsChatExpired(false);
            setSendMessageLoading(false);
            const newMessage = response.data.result;
            // await dispatch(updateSentMessage(newMessage));
            await dispatch(getAllChats());
            // dispatch(getAllMessagesByChatId({ chatId: selectedChat._id }));
            scrollToBottom();

            setFiles("");
            setTextMessage("");
         }
      } catch (err) {
         setSendMessageLoading(false);
         console.log(err);
      }
   };

   const handleKeyDown = (e) => {
      if (e.key === "Enter" || e.keyCode === 13) {
         handleSendMessage();
      }
   };
   const handleRemoveFile = () => {
      setFiles("");
      setMessageType("");
   };

   const getMessageComponent = (type, content) => {
      if (type === "text") return <TextMessage content={content} />;
      if (type === "template")
         return <TemplateMessage content={content} templates={templates} />;
      // if (type === "template") return <p>{content}template</p>;
      if (type === "document") return <DocumentMessage content={content} />;
      if (type === "image" || type === "video")
         return <ImageMessage content={content} />;
      if (type === "audio") return <AudioMessage content={content} />;
      if (type === "button") return <ButtonMessage content={content} />;
      if (type === "interactive")
         return <InteractiveMessage content={content} />;
   };

   const handleFileChange = (e) => {
      const file = e.target.files[0];

      if (file && fileTypes.includes(file.type)) {
         setFiles(file);
         console.log(`File type: ${file.type} accepted.`);
      } else {
         setFiles(null); // Optionally clear the file input
         console.error("File type not accepted.");
         toast.error("The selected file type is not accepted.");
      }
   };
   const handleClickFileUpload = () => {
      fileuploadRef.current.click();
   };

   const handleLoadMore = () => {
      const pageNumber = Number(page) + 1;
      dispatch(
         loadMoreChatByChatId({ chatId: selectedChat._id, page: pageNumber })
      );
      dispatch(updatePageNumber());
   };

   return (
      <>
         <Toaster />
         {Object.keys(selectedChat).length === 0 && (
            <ChatBox
               sx={{
                  marginLeft: matches ? "20px" : "0px",
                  marginTop: matches ? "0px" : "20px",
               }}
            >
               <Box
                  height="100%"
                  width="100%"
                  textAlign="center"
                  alignContent="center"
               >
                  <Typography variant="body1" color="initial">
                     Select a chat to continue..
                  </Typography>
               </Box>
            </ChatBox>
         )}

         {Object.keys(selectedChat).length > 0 && (
            <ChatBox
               sx={{
                  marginLeft: matches ? "20px" : "0px",
                  marginTop: matches ? "0px" : "20px",
               }}
            >
               <Box></Box>
               {loading ? (
                  <ChatHeaderSkeleton />
               ) : (
                  <ChatHeader
                     selectedChat={selectedChat}
                     lastMessageTime={"10"}
                  />
               )}
               {files ? (
                  <FileDisplayBox
                     file={files}
                     handleRemoveFile={handleRemoveFile}
                     setTextMessage={setTextMessage}
                     textMessage={textMessage}
                     handleSendMessage={handleSendMessage}
                     setMessageType={setMessageType}
                     loading={sendMessageLoading}
                  />
               ) : (
                  <>
                     {loading ? (
                        <LoadingBox>
                           <CircularProgress sx={{ color: "black" }} />
                        </LoadingBox>
                     ) : (
                        <ContentBox ref={contentBoxRef}>
                           {showLoadMoreButton &&
                              selectedChat?._id !== "new" && (
                                 <Box
                                    sx={{
                                       display: "flex",
                                       alignItems: "center",
                                       justifyContent: "center",
                                       padding: "5px",
                                    }}
                                 >
                                    <LoadMoreButton
                                       variant="contained"
                                       onClick={() => handleLoadMore()}
                                    >
                                       Load More
                                    </LoadMoreButton>
                                 </Box>
                              )}
                           {loadedMessage?.map((mess) => (
                              <div key={mess?._id}>
                                 <div
                                    style={{
                                       display: "flex",
                                       alignItems: "center",
                                       justifyContent: "center",
                                    }}
                                 >
                                    <DateBox>
                                       <span
                                          style={{
                                             color: "grey",
                                             fontSize: "12px",
                                          }}
                                       >
                                          {mess?._id}
                                       </span>
                                    </DateBox>
                                 </div>

                                 {mess?.messages?.map((msg) => (
                                    <div>
                                       {getMessageComponent(
                                          msg?.message_type,
                                          msg
                                       )}
                                    </div>
                                 ))}
                              </div>
                           ))}
                           {messageList?.map((mess) => (
                              <div key={mess?._id}>
                                 <div
                                    style={{
                                       display: "flex",
                                       alignItems: "center",
                                       justifyContent: "center",
                                    }}
                                 >
                                    <DateBox>
                                       <span
                                          style={{
                                             color: "grey",
                                             fontSize: "12px",
                                          }}
                                       >
                                          {mess?._id}
                                       </span>
                                    </DateBox>
                                 </div>

                                 {mess?.messages?.map((msg) => (
                                    <div>
                                       {getMessageComponent(
                                          msg?.message_type,
                                          msg
                                       )}
                                    </div>
                                 ))}
                              </div>
                           ))}
                           {newMessages?.messages?.map((mess) => (
                              <div key={mess?._id}>
                                 {getMessageComponent(mess?.message_type, mess)}
                              </div>
                           ))}
                        </ContentBox>
                     )}

                     <ChatInput
                        openTemplate={openTemplate}
                        setOpenTemplate={setOpenTemplate}
                        selectedChat={selectedChat}
                        setMessageList={setMessageList}
                        isChatExpired={isChatExpired}
                        handleClickFileUpload={handleClickFileUpload}
                        fileuploadRef={fileuploadRef}
                        handleFileChange={handleFileChange}
                        textMessage={textMessage}
                        handleChange={handleChange}
                        started={started}
                        setStarted={setStarted}
                        handleSendMessage={handleSendMessage}
                        sendMessageLoading={sendMessageLoading}
                        handleKeyDown={handleKeyDown}
                     />
                  </>
               )}
            </ChatBox>
         )}
      </>
   );
}

export default Chat;
