import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  // palette: {
  //   mode: 'dark',
  // },
  typography: {
    fontFamily: `"Inter", sans-serif`, // Replace with your font family
  },
});
export default theme;
