import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
   Collapse,
   Divider,
   List,
   ListItem,
   ListItemButton,
   ListItemIcon,
   ListItemText,
   SvgIcon,
   Typography,
   styled,
} from "@mui/material";
import React, { useState } from "react";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import LeaderboardRoundedIcon from "@mui/icons-material/LeaderboardRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";

import TelegramIcon from "@mui/icons-material/Telegram";
import { useNavigate } from "react-router-dom";

const CustomText = styled(Typography)({
   fontSize: "15px",
   color: "#fff",
});

const DrawerHeader = styled("div")(({ theme }) => ({
   display: "flex",
   alignItems: "center",
   justifyContent: "flex-end",
   backgroundColor: "black",

   padding: theme.spacing(0, 1),
   // necessary for content to be below app bar
   ...theme.mixins.toolbar,
}));

const SubListItem = styled(ListItemButton)({
   "& .MuiListItemIcon-root": {
      minWidth: "30px !important",
      height: "40px",
      alignItems: "center",
      // paddingLeft:"18px"
   },
});

function SidebarList() {
   const [open, setOpen] = useState(false);
   const navigate = useNavigate();
   const [openChannels, setOpenChannel] = useState(false);
   const [openSettings, setOpenSettings] = useState(false);
   const [openTemplates, setopenTemplates] = useState(false);

   // const navigate = useNavigate();

   // const handleOpenChannel = () => setOpenChannel(!openChannels);
   // const hanldeOpenSettings = () => setOpenSettings(!openSettings);
   const handleOpenChannel = () => setOpenChannel(!openChannels);
   const hanldeOpenSettings = () => setOpenSettings(!openSettings);
   const hanldeOpenTemplates = () => setopenTemplates(!openTemplates);

   const handleLogout = () => {
      localStorage.setItem("user", null);
      localStorage.setItem("login", false);
      navigate("/");
   };
   return (
      <>
         <DrawerHeader>
            {/* <img
          src="https://gingertechnologies.in/images/footer_logo.svg"
          alt="logo"
          style={{ height: "auto", marginTop: "5px" }}
        /> */}
            <Typography>gingerconsultancyservice.com</Typography>
         </DrawerHeader>
         <Divider />
         <List>
            <ListItemButton sx={{ marginTop: "5px" }}>
               <ListItemText
                  sx={{
                     color: "#fff",
                     "& .MuiTypography-root": { fontSize: "8px" },
                  }}
                  primary="MAIN"
               />
            </ListItemButton>
            <ListItem disablePadding sx={{ display: "block" }}>
               <ListItemButton
                  onClick={() => navigate("/dashboard")}
                  sx={{
                     "& .MuiListItemIcon-root": {
                        minWidth: "30px !important",
                        height: "40px",
                        alignItems: "center",
                     },
                  }}
               >
                  <ListItemIcon
                     sx={{
                        color: "#fff",
                        "& .MuiSvgIcon-root": {
                           fontSize: "18px",
                        },
                     }}
                  >
                     <HomeRoundedIcon />
                  </ListItemIcon>
                  <ListItemText
                     sx={{
                        opacity: open ? 1 : 0,
                        color: "#fff",
                        fontSize: "15px",
                     }}
                     primary="Dashboard"
                  />
               </ListItemButton>
            </ListItem>
            <ListItemButton>
               <ListItemText
                  sx={{
                     color: "#fff",
                     "& .MuiTypography-root": { fontSize: "8px" },
                  }}
                  primary="Manage"
               />
            </ListItemButton>
            <ListItemButton
               sx={{
                  "& .MuiListItemIcon-root": {
                     minWidth: "30px !important",
                     height: "40px",
                     alignItems: "center",
                  },
               }}
            >
               <ListItemIcon
                  sx={{
                     color: "#fff",
                     "& .MuiSvgIcon-root": {
                        fontSize: "18px",
                     },
                  }}
               >
                  <SmsOutlinedIcon sx={{ color: "#fff" }} />
               </ListItemIcon>
               <ListItemText
                  sx={{
                     opacity: open ? 1 : 0,
                     color: "#fff",
                     fontSize: "15px",
                  }}
                  primary="Inbox"
               />
            </ListItemButton>
            <ListItemButton
               onClick={hanldeOpenTemplates}
               sx={{
                  "& .MuiListItemIcon-root": {
                     minWidth: "30px !important",
                     height: "40px",
                     alignItems: "center",
                  },
               }}
            >
               <ListItemIcon
                  sx={{
                     color: "#fff",
                     "& .MuiSvgIcon-root": {
                        fontSize: "18px",
                     },
                  }}
               >
                  <FontAwesomeIcon icon="fa-regular fa-folder" />
               </ListItemIcon>
               <ListItemText
                  sx={{
                     opacity: open ? 1 : 0,
                     color: "#fff",
                     fontSize: "15px",
                  }}
                  primary="Templates"
               />
            </ListItemButton>
            <Collapse in={openTemplates} timeout="auto" unmountOnExit>
               <List component="div" disablePadding>
                  <SubListItem
                     sx={{ pl: 4 }}
                     onClick={() => navigate("/templates")}
                  >
                     <ListItemIcon
                        sx={{
                           color: "#fff",
                           "& .MuiSvgIcon-root": {
                              fontSize: "18px",
                           },
                        }}
                     >
                        <FormatListBulletedIcon />
                     </ListItemIcon>
                     <ListItemText
                        sx={{ color: "#fff" }}
                        primary="Templates List"
                     />
                  </SubListItem>
                  <SubListItem
                     sx={{ pl: 4 }}
                     onClick={() => navigate("/create-template")}
                  >
                     <ListItemIcon
                        sx={{
                           color: "#fff",
                           "& .MuiSvgIcon-root": {
                              fontSize: "18px",
                           },
                        }}
                     >
                        <CreateNewFolderIcon />
                     </ListItemIcon>
                     <ListItemText
                        sx={{ color: "#fff" }}
                        primary="Create Templates"
                     />
                  </SubListItem>
               </List>
            </Collapse>
            {/* <ListItemButton
          onClick={handleOpenChannel}
          sx={{
            "& .MuiListItemIcon-root": {
              minWidth: "30px !important",
              height: "40px",
              alignItems: "center",
            },
          }}
        >
          <ListItemIcon
            sx={{
              color: "#fff",
              "& .MuiSvgIcon-root": {
                fontSize: "18px",
              },
            }}
          >
            <DashboardOutlinedIcon sx={{ color: "#fff" }} />
          </ListItemIcon>
          <ListItemText
            sx={{ opacity: open ? 1 : 0, color: "#fff", fontSize: "15px" }}
            primary="Channels"
          />
        </ListItemButton> */}
            {/* <Collapse in={openChannels} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <SubListItem sx={{ pl: 4 }}>
              <ListItemIcon
                sx={{
                  color: "#fff",
                  "& .MuiSvgIcon-root": {
                    fontSize: "18px",
                  },
                }}
              >
                <FontAwesomeIcon icon="fa-brands fa-whatsapp" />
              </ListItemIcon>
              <ListItemText sx={{ color: "#fff" }} primary="Whatsapp" />
            </SubListItem>
            <SubListItem sx={{ pl: 4 }}>
              <ListItemIcon
                sx={{
                  color: "#fff",
                  "& .MuiSvgIcon-root": {
                    fontSize: "18px",
                  },
                }}
              >
                <FontAwesomeIcon icon="fa-brands fa-facebook-messenger" />
              </ListItemIcon>
              <ListItemText sx={{ color: "#fff" }} primary="Messenger" />
            </SubListItem>
            <SubListItem sx={{ pl: 4 }}>
              <ListItemIcon
                sx={{
                  color: "#fff",
                  "& .MuiSvgIcon-root": {
                    fontSize: "18px",
                  },
                }}
              >
                <TelegramIcon />
              </ListItemIcon>
              <ListItemText
                sx={{ color: "#fff" }}
                primary="Insta Direct Message"
              />
            </SubListItem>
            <SubListItem sx={{ pl: 4 }}>
              <ListItemIcon
                sx={{
                  color: "#fff",
                  "& .MuiSvgIcon-root": {
                    fontSize: "18px",
                  },
                }}
              >
                <img src="http://127.0.0.1:5500/images/email.svg" alt="snap" />
                <SvgIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="100"
                    height="100"
                    viewBox="0,0,256,256"
                  >
                    <g
                      fill="none"
                      fillRule="nonzero"
                      stroke="none"
                      strokeWidth="1"
                      strokeLinecap="butt"
                      stroke-linejoin="miter"
                      strokeMiterlimit="10"
                      stroke-dashoffset="0"
                      // font-family='none'
                      // font-weight='none'
                      // font-size='none'
                      textAnchor="none"
                    >
                      <g transform="scale(5.33333,5.33333)">
                        <path
                          d="M45,16.2l-5,2.75l-5,4.75v16.3h7c1.657,0 3,-1.343 3,-3z"
                          fill="#000000"
                        ></path>
                        <path
                          d="M3,16.2l3.614,1.71l6.386,5.79v16.3h-7c-1.657,0 -3,-1.343 -3,-3z"
                          fill="#000000"
                        ></path>
                        <path
                          d="M35,11.2l-11,8.25l-11,-8.25l-1,5.8l1,6.7l11,8.25l11,-8.25l1,-6.7z"
                          fill="#050505"
                        ></path>
                        <path
                          d="M3,12.298v3.902l10,7.5v-12.5l-3.124,-2.341c-0.744,-0.558 -1.648,-0.859 -2.578,-0.859v0c-2.374,0 -4.298,1.924 -4.298,4.298z"
                          fill="#000000"
                        ></path>
                        <path
                          d="M45,12.298v3.902l-10,7.5v-12.5l3.124,-2.341c0.744,-0.558 1.648,-0.859 2.578,-0.859v0c2.374,0 4.298,1.924 4.298,4.298z"
                          fill="#000000"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </SvgIcon>
              </ListItemIcon>

              <ListItemText sx={{ color: "#fff" }} primary="Email" />
            </SubListItem>
            <SubListItem sx={{ pl: 4 }}>
              <ListItemIcon
                sx={{
                  color: "#fff",
                  "& .MuiSvgIcon-root": {
                    fontSize: "18px",
                  },
                }}
              >
                <FontAwesomeIcon icon="fa-brands fa-snapchat" />
              </ListItemIcon>
              <ListItemText sx={{ color: "#fff" }} primary="Snapchat" />
            </SubListItem>
            <SubListItem sx={{ pl: 4 }}>
              <ListItemIcon
                sx={{
                  color: "#fff",
                  "& .MuiSvgIcon-root": {
                    fontSize: "18px",
                  },
                }}
              >
                <SvgIcon>
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1_28)">
                      <path
                        d="M10.837 11.872C11.865 11.872 12.583 11.143 12.583 10.097C12.583 9.972 12.568 9.875 12.555 9.795L12.548 9.754H10.788V10.459H11.778C11.713 10.717 11.454 11.121 10.838 11.121C10.262 11.121 9.793 10.642 9.793 10.056C9.793 9.47 10.262 8.991 10.838 8.991C11.169 8.991 11.384 9.132 11.507 9.25L11.542 9.283L12.094 8.751L12.055 8.716C11.722 8.405 11.301 8.241 10.837 8.241C9.836 8.241 9.021 9.056 9.021 10.057C9.021 11.058 9.835 11.873 10.837 11.873V11.872Z"
                        fill="white"
                      />
                      <path
                        d="M14.528 1.029C14.396 0.433 13.858 0 13.248 0H2.153C1.543 0 1.004 0.433 0.872 1.029L0.005 4.959L0 5.037C0 5.805 0.373 6.509 1.003 6.941V12.181C1.003 12.901 1.589 13.487 2.309 13.487H13.128C13.848 13.487 14.435 12.901 14.435 12.181V6.913C15.041 6.478 15.4 5.785 15.4 5.036L14.527 1.028L14.528 1.029ZM3.593 5.037C3.593 5.744 3.018 6.318 2.312 6.318C1.621 6.318 1.046 5.756 1.026 5.112L1.879 1.25C1.907 1.123 2.023 1.03 2.154 1.03H4.084L3.593 5.037ZM7.185 5.037C7.185 5.744 6.61 6.318 5.904 6.318C5.206 6.318 4.632 5.75 4.622 5.079L5.122 1.03H7.186L7.185 5.037ZM2.023 7.33C2.823 7.427 3.611 7.105 4.107 6.49C4.544 7.032 5.196 7.348 5.903 7.348C6.61 7.348 7.262 7.032 7.699 6.49C8.136 7.032 8.789 7.348 9.495 7.348C10.201 7.348 10.854 7.032 11.291 6.49C11.795 7.116 12.61 7.437 13.413 7.325V12.181C13.413 12.339 13.285 12.467 13.127 12.467H2.31C2.152 12.467 2.024 12.339 2.024 12.181V7.329L2.023 7.33ZM9.496 6.318C8.789 6.318 8.215 5.743 8.215 5.037V1.03H10.279L10.777 5.066C10.762 5.757 10.19 6.318 9.496 6.318ZM11.316 1.03H13.247C13.378 1.03 13.493 1.123 13.521 1.25L14.369 5.065C14.354 5.756 13.78 6.318 13.088 6.318C12.381 6.318 11.807 5.743 11.806 4.993L11.316 1.03Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_28">
                        <rect width="15.4" height="13.487" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </SvgIcon>
              </ListItemIcon>
              <ListItemText sx={{ color: "#fff" }} primary="My Business" />
            </SubListItem>
            <SubListItem sx={{ pl: 4 }}>
              <ListItemIcon
                sx={{
                  color: "#fff",
                  "& .MuiSvgIcon-root": {
                    fontSize: "18px",
                  },
                }}
              >
                <SvgIcon>
                  <svg
                    width="15"
                    height="21"
                    viewBox="0 0 15 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1_56)">
                      <mask
                        id="mask0_1_56"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="15"
                        height="21"
                      >
                        <path d="M15 0.5H0V20.64H15V0.5Z" fill="white" />
                      </mask>
                      <g mask="url(#mask0_1_56)">
                        <path
                          d="M4.00689 15.391H1.10889V14.976H3.86289L6.04389 13.261L6.30089 13.587L4.00689 15.391Z"
                          fill="white"
                        />
                        <path
                          d="M0.652 15.814C1.012 15.814 1.304 15.522 1.304 15.161C1.304 14.8 1.012 14.508 0.652 14.508C0.292 14.508 0 14.8 0 15.161C0 15.522 0.292 15.814 0.652 15.814Z"
                          fill="white"
                        />
                        <path
                          d="M6.19989 9.83201L3.86389 8.04401H1.10889V7.62701H4.00589L6.45389 9.50001L6.20089 9.83201H6.19989Z"
                          fill="white"
                        />
                        <path
                          d="M0.652 8.50901C1.012 8.50901 1.304 8.21701 1.304 7.85701C1.304 7.49701 1.012 7.20401 0.652 7.20401C0.292 7.20401 0 7.49601 0 7.85701C0 8.21801 0.292 8.50901 0.652 8.50901Z"
                          fill="white"
                        />
                        <path
                          d="M5.78303 11.389H2.95703V11.804H5.78303V11.389Z"
                          fill="white"
                        />
                        <path
                          d="M2.7289 12.248C3.0889 12.248 3.3819 11.956 3.3819 11.595C3.3819 11.234 3.0899 10.942 2.7289 10.942C2.3679 10.942 2.0769 11.234 2.0769 11.595C2.0769 11.956 2.3689 12.248 2.7289 12.248Z"
                          fill="white"
                        />
                        <path
                          d="M6.08589 5.573L4.03589 4.559V1.409H4.45189V4.301L6.27089 5.199L6.08589 5.573Z"
                          fill="white"
                        />
                        <path
                          d="M4.24309 1.844C4.60309 1.844 4.89509 1.552 4.89509 1.192C4.89509 0.832001 4.60409 0.539001 4.24309 0.539001C3.88209 0.539001 3.59009 0.831001 3.59009 1.191C3.59009 1.551 3.88209 1.844 4.24309 1.844Z"
                          fill="white"
                        />
                        <path
                          d="M9.84805 16.377V19.379L7.50005 20.64L5.15405 19.379V16.377L7.11005 15.138V14.4L5.78105 13.422V9.96599L7.21605 8.91999V8.09499L5.80505 7.29099V5.50799L7.50005 4.05099L9.19705 5.50799V7.29099L7.78205 8.09499V8.91999L9.21805 9.96599V13.422L7.89205 14.4V15.138L9.84805 16.377Z"
                          fill="white"
                        />
                        <path
                          d="M13.8919 15.422H10.9949L8.68091 13.658L8.93291 13.326L11.1349 15.005H13.8919V15.422Z"
                          fill="white"
                        />
                        <path
                          d="M14.3471 15.844C14.7071 15.844 15.0001 15.552 15.0001 15.191C15.0001 14.83 14.7081 14.538 14.3471 14.538C13.9861 14.538 13.6941 14.83 13.6941 15.191C13.6941 15.552 13.9861 15.844 14.3471 15.844Z"
                          fill="white"
                        />
                        <path
                          d="M8.80012 9.86202L8.54712 9.53202L10.9951 7.65802H13.8921V8.07402H11.1361L8.80012 9.86202Z"
                          fill="white"
                        />
                        <path
                          d="M14.3471 8.54002C14.7071 8.54002 15.0001 8.24802 15.0001 7.88702C15.0001 7.52602 14.7081 7.23502 14.3471 7.23502C13.9861 7.23502 13.6941 7.52702 13.6941 7.88702C13.6941 8.24702 13.9861 8.54002 14.3471 8.54002Z"
                          fill="white"
                        />
                        <path
                          d="M12.043 11.418H9.21704V11.833H12.043V11.418Z"
                          fill="white"
                        />
                        <path
                          d="M12.2709 12.28C12.6309 12.28 12.9239 11.988 12.9239 11.627C12.9239 11.266 12.6319 10.974 12.2709 10.974C11.9099 10.974 11.6189 11.266 11.6189 11.627C11.6189 11.988 11.9109 12.28 12.2709 12.28Z"
                          fill="white"
                        />
                        <path
                          d="M8.98307 5.534L8.79907 5.161L10.6181 4.263V1.37H11.0341V4.52L8.98407 5.534H8.98307Z"
                          fill="white"
                        />
                        <path
                          d="M10.8271 1.80501C11.1871 1.80501 11.4801 1.51301 11.4801 1.15301C11.4801 0.793007 11.1881 0.501007 10.8271 0.501007C10.4661 0.501007 10.1741 0.793007 10.1741 1.15301C10.1741 1.51301 10.4661 1.80501 10.8271 1.80501Z"
                          fill="white"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_1_56">
                        <rect
                          width="15"
                          height="20.14"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </SvgIcon>
              </ListItemIcon>
              <ListItemText sx={{ color: "#fff" }} primary="CipherAnts" />
            </SubListItem>
          </List>
        </Collapse> */}
            {/* {[{ name: "Insights", icon: <LeaderboardRoundedIcon /> }].map(
          (item) => (
            <ListItemButton
              key={item.name}
              sx={{
                "& .MuiListItemIcon-root": {
                  minWidth: "30px !important",
                  height: "50px",
                  alignItems: "center",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  color: "#fff",
                  "& .MuiSvgIcon-root": {
                    fontSize: "18px",
                  },
                }}
              >
                {item.icon}
              </ListItemIcon>
              <CustomText sx={{ opacity: open ? 1 : 0 }}>
                {item.name}
              </CustomText>
            </ListItemButton>
          )
        )} */}

            {/* <ListItemButton>
          <ListItemText
            sx={{
              color: "#fff",
              "& .MuiTypography-root": { fontSize: "8px" },
            }}
            primary="Settings"
          />
        </ListItemButton>
        {[{ name: "Settings", icon: <SettingsRoundedIcon /> }].map((item) => (
          <ListItemButton
            onClick={hanldeOpenSettings}
            key={item.name}
            sx={{
              "& .MuiListItemIcon-root": {
                minWidth: "30px !important",
              },
            }}
          >
            <ListItemIcon
              sx={{
                color: "#fff",
                "& .MuiSvgIcon-root": {
                  fontSize: "18px",
                },
              }}
            >
              {item.icon}
            </ListItemIcon>
            <CustomText sx={{ opacity: open ? 1 : 0 }}>{item.name}</CustomText>
          </ListItemButton>
        ))}
        <Collapse in={openSettings} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <SubListItem sx={{ pl: 4 }}>
              <ListItemIcon
                sx={{
                  color: "#fff",
                  "& .MuiSvgIcon-root": {
                    fontSize: "18px",
                  },
                }}
              >
                <DataSaverOnIcon />
              </ListItemIcon>
              <ListItemText primary="Create Roles" />
            </SubListItem>
            <SubListItem sx={{ pl: 4 }}>
              <ListItemIcon
                sx={{
                  color: "#fff",
                  "& .MuiSvgIcon-root": {
                    fontSize: "18px",
                  },
                }}
              >
                <PersonAddIcon />
              </ListItemIcon>
              <ListItemText
                primary="Create Users"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </SubListItem>
          </List>
        </Collapse> */}
         </List>
         <List>
            <ListItemButton
               sx={{
                  "& .MuiListItemIcon-root": {
                     minWidth: "30px !important",
                     fontSize: "15px",
                  },
               }}
            >
               <ListItemIcon
                  sx={{
                     color: "#fff",
                     "& .MuiSvgIcon-root": {
                        fontSize: "18px",
                     },
                  }}
               >
                  <PersonIcon />
               </ListItemIcon>
               <CustomText sx={{ opacity: open ? 1 : 0 }}>Profile</CustomText>
            </ListItemButton>
            <ListItemButton
               onClick={() => handleLogout()}
               sx={{
                  "& .MuiListItemIcon-root": {
                     minWidth: "30px !important",
                  },
               }}
            >
               <ListItemIcon
                  sx={{
                     color: "#fff",
                     "& .MuiSvgIcon-root": {
                        fontSize: "18px",
                     },
                  }}
               >
                  <LogoutIcon />
               </ListItemIcon>
               <CustomText sx={{ opacity: open ? 1 : 0 }}>Logout</CustomText>
            </ListItemButton>
         </List>

         {/* <List>
        <ListItemButton sx={{ marginTop: "5px" }}>
          <ListItemText
            sx={{
              color: "#fff",
              "& .MuiTypography-root": { fontSize: "8px" },
            }}
            primary="MAIN"
          />
        </ListItemButton>
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              "& .MuiListItemIcon-root": {
                minWidth: "30px !important",
                height: "40px",
                alignItems: "center",
              },
            }}
          >
            <ListItemIcon
              sx={{
                color: "#fff",
                "& .MuiSvgIcon-root": {
                  fontSize: "18px",
                },
              }}
            >
              <HomeRoundedIcon />
            </ListItemIcon>
            <ListItemText
              sx={{ color: "#fff", fontSize: "15px" }}
              primary="Dashboard"
            />
          </ListItemButton>
        </ListItem>
        <ListItemButton>
          <ListItemText
            sx={{
              color: "#fff",
              "& .MuiTypography-root": { fontSize: "8px" },
            }}
            primary="Manage"
          />
        </ListItemButton>
        <ListItemButton
          sx={{
            "& .MuiListItemIcon-root": {
              minWidth: "30px !important",
              height: "40px",
              alignItems: "center",
            },
          }}
        >
          <ListItemIcon
            sx={{
              color: "#fff",
              "& .MuiSvgIcon-root": {
                fontSize: "18px",
              },
            }}
          >
            <SmsOutlinedIcon sx={{ color: "#fff" }} />
          </ListItemIcon>
          <ListItemText
            sx={{ color: "#fff", fontSize: "15px" }}
            primary="Inbox"
          />
        </ListItemButton>
        <ListItemButton
          onClick={handleOpenChannel}
          sx={{
            "& .MuiListItemIcon-root": {
              minWidth: "30px !important",
              height: "40px",
              alignItems: "center",
            },
          }}
        >
          <ListItemIcon
            sx={{
              color: "#fff",
              "& .MuiSvgIcon-root": {
                fontSize: "18px",
              },
            }}
          >
            <DashboardOutlinedIcon sx={{ color: "#fff" }} />
          </ListItemIcon>
          <ListItemText
            sx={{ color: "#fff", fontSize: "15px" }}
            primary="Channels"
          />
        </ListItemButton>
        <Collapse in={openChannels} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <SubListItem sx={{ pl: 4 }}>
              <ListItemIcon
                sx={{
                  color: "#fff",
                  "& .MuiSvgIcon-root": {
                    fontSize: "18px",
                  },
                }}
              >
                <FontAwesomeIcon icon="fa-brands fa-whatsapp" />
              </ListItemIcon>
              <ListItemText primary="Whatsapp" />
            </SubListItem>
            <SubListItem sx={{ pl: 4 }}>
              <ListItemIcon
                sx={{
                  color: "#fff",
                  "& .MuiSvgIcon-root": {
                    fontSize: "18px",
                  },
                }}
              >
                <FontAwesomeIcon icon="fa-brands fa-facebook-messenger" />
              </ListItemIcon>
              <ListItemText primary="Messenger" />
            </SubListItem>
            <SubListItem sx={{ pl: 4 }}>
              <ListItemIcon
                sx={{
                  color: "#fff",
                  "& .MuiSvgIcon-root": {
                    fontSize: "18px",
                  },
                }}
              >
                <TelegramIcon />
              </ListItemIcon>
              <ListItemText primary="Insta Direct Message" />
            </SubListItem>
            <SubListItem sx={{ pl: 4 }}>
              <ListItemIcon
                sx={{
                  color: "#fff",
                  "& .MuiSvgIcon-root": {
                    fontSize: "18px",
                  },
                }}
              >
                <img src="http://127.0.0.1:5500/images/email.svg" alt="snap" />
                <SvgIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="100"
                    height="100"
                    viewBox="0,0,256,256"
                  >
                    <g
                      fill="none"
                      fillRule="nonzero"
                      stroke="none"
                      strokeWidth="1"
                      strokeLinecap="butt"
                      stroke-linejoin="miter"
                      stroke-miterlimit="10"
                      stroke-dashoffset="0"
                      fontFamily="none"
                      fontWeight="none"
                      // font-size="none"
                      textAnchor="none"
                    >
                      <g transform="scale(5.33333,5.33333)">
                        <path
                          d="M45,16.2l-5,2.75l-5,4.75v16.3h7c1.657,0 3,-1.343 3,-3z"
                          fill="#000000"
                        ></path>
                        <path
                          d="M3,16.2l3.614,1.71l6.386,5.79v16.3h-7c-1.657,0 -3,-1.343 -3,-3z"
                          fill="#000000"
                        ></path>
                        <path
                          d="M35,11.2l-11,8.25l-11,-8.25l-1,5.8l1,6.7l11,8.25l11,-8.25l1,-6.7z"
                          fill="#050505"
                        ></path>
                        <path
                          d="M3,12.298v3.902l10,7.5v-12.5l-3.124,-2.341c-0.744,-0.558 -1.648,-0.859 -2.578,-0.859v0c-2.374,0 -4.298,1.924 -4.298,4.298z"
                          fill="#000000"
                        ></path>
                        <path
                          d="M45,12.298v3.902l-10,7.5v-12.5l3.124,-2.341c0.744,-0.558 1.648,-0.859 2.578,-0.859v0c2.374,0 4.298,1.924 4.298,4.298z"
                          fill="#000000"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </SvgIcon>
              </ListItemIcon>

              <ListItemText primary="Email" />
            </SubListItem>
            <SubListItem sx={{ pl: 4 }}>
              <ListItemIcon
                sx={{
                  color: "#fff",
                  "& .MuiSvgIcon-root": {
                    fontSize: "18px",
                  },
                }}
              >
                <FontAwesomeIcon icon="fa-brands fa-snapchat" />
              </ListItemIcon>
              <ListItemText primary="Snapchat" />
            </SubListItem>
            <SubListItem sx={{ pl: 4 }}>
              <ListItemIcon
                sx={{
                  color: "#fff",
                  "& .MuiSvgIcon-root": {
                    fontSize: "18px",
                  },
                }}
              >
                <SvgIcon>
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1_28)">
                      <path
                        d="M10.837 11.872C11.865 11.872 12.583 11.143 12.583 10.097C12.583 9.972 12.568 9.875 12.555 9.795L12.548 9.754H10.788V10.459H11.778C11.713 10.717 11.454 11.121 10.838 11.121C10.262 11.121 9.793 10.642 9.793 10.056C9.793 9.47 10.262 8.991 10.838 8.991C11.169 8.991 11.384 9.132 11.507 9.25L11.542 9.283L12.094 8.751L12.055 8.716C11.722 8.405 11.301 8.241 10.837 8.241C9.836 8.241 9.021 9.056 9.021 10.057C9.021 11.058 9.835 11.873 10.837 11.873V11.872Z"
                        fill="white"
                      />
                      <path
                        d="M14.528 1.029C14.396 0.433 13.858 0 13.248 0H2.153C1.543 0 1.004 0.433 0.872 1.029L0.005 4.959L0 5.037C0 5.805 0.373 6.509 1.003 6.941V12.181C1.003 12.901 1.589 13.487 2.309 13.487H13.128C13.848 13.487 14.435 12.901 14.435 12.181V6.913C15.041 6.478 15.4 5.785 15.4 5.036L14.527 1.028L14.528 1.029ZM3.593 5.037C3.593 5.744 3.018 6.318 2.312 6.318C1.621 6.318 1.046 5.756 1.026 5.112L1.879 1.25C1.907 1.123 2.023 1.03 2.154 1.03H4.084L3.593 5.037ZM7.185 5.037C7.185 5.744 6.61 6.318 5.904 6.318C5.206 6.318 4.632 5.75 4.622 5.079L5.122 1.03H7.186L7.185 5.037ZM2.023 7.33C2.823 7.427 3.611 7.105 4.107 6.49C4.544 7.032 5.196 7.348 5.903 7.348C6.61 7.348 7.262 7.032 7.699 6.49C8.136 7.032 8.789 7.348 9.495 7.348C10.201 7.348 10.854 7.032 11.291 6.49C11.795 7.116 12.61 7.437 13.413 7.325V12.181C13.413 12.339 13.285 12.467 13.127 12.467H2.31C2.152 12.467 2.024 12.339 2.024 12.181V7.329L2.023 7.33ZM9.496 6.318C8.789 6.318 8.215 5.743 8.215 5.037V1.03H10.279L10.777 5.066C10.762 5.757 10.19 6.318 9.496 6.318ZM11.316 1.03H13.247C13.378 1.03 13.493 1.123 13.521 1.25L14.369 5.065C14.354 5.756 13.78 6.318 13.088 6.318C12.381 6.318 11.807 5.743 11.806 4.993L11.316 1.03Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_28">
                        <rect width="15.4" height="13.487" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </SvgIcon>
              </ListItemIcon>
              <ListItemText primary="My Business" />
            </SubListItem>
            <SubListItem sx={{ pl: 4 }}>
              <ListItemIcon
                sx={{
                  color: "#fff",
                  "& .MuiSvgIcon-root": {
                    fontSize: "18px",
                  },
                }}
              >
                <SvgIcon>
                  <svg
                    width="15"
                    height="21"
                    viewBox="0 0 15 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1_56)">
                      <mask
                        id="mask0_1_56"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="15"
                        height="21"
                      >
                        <path d="M15 0.5H0V20.64H15V0.5Z" fill="white" />
                      </mask>
                      <g mask="url(#mask0_1_56)">
                        <path
                          d="M4.00689 15.391H1.10889V14.976H3.86289L6.04389 13.261L6.30089 13.587L4.00689 15.391Z"
                          fill="white"
                        />
                        <path
                          d="M0.652 15.814C1.012 15.814 1.304 15.522 1.304 15.161C1.304 14.8 1.012 14.508 0.652 14.508C0.292 14.508 0 14.8 0 15.161C0 15.522 0.292 15.814 0.652 15.814Z"
                          fill="white"
                        />
                        <path
                          d="M6.19989 9.83201L3.86389 8.04401H1.10889V7.62701H4.00589L6.45389 9.50001L6.20089 9.83201H6.19989Z"
                          fill="white"
                        />
                        <path
                          d="M0.652 8.50901C1.012 8.50901 1.304 8.21701 1.304 7.85701C1.304 7.49701 1.012 7.20401 0.652 7.20401C0.292 7.20401 0 7.49601 0 7.85701C0 8.21801 0.292 8.50901 0.652 8.50901Z"
                          fill="white"
                        />
                        <path
                          d="M5.78303 11.389H2.95703V11.804H5.78303V11.389Z"
                          fill="white"
                        />
                        <path
                          d="M2.7289 12.248C3.0889 12.248 3.3819 11.956 3.3819 11.595C3.3819 11.234 3.0899 10.942 2.7289 10.942C2.3679 10.942 2.0769 11.234 2.0769 11.595C2.0769 11.956 2.3689 12.248 2.7289 12.248Z"
                          fill="white"
                        />
                        <path
                          d="M6.08589 5.573L4.03589 4.559V1.409H4.45189V4.301L6.27089 5.199L6.08589 5.573Z"
                          fill="white"
                        />
                        <path
                          d="M4.24309 1.844C4.60309 1.844 4.89509 1.552 4.89509 1.192C4.89509 0.832001 4.60409 0.539001 4.24309 0.539001C3.88209 0.539001 3.59009 0.831001 3.59009 1.191C3.59009 1.551 3.88209 1.844 4.24309 1.844Z"
                          fill="white"
                        />
                        <path
                          d="M9.84805 16.377V19.379L7.50005 20.64L5.15405 19.379V16.377L7.11005 15.138V14.4L5.78105 13.422V9.96599L7.21605 8.91999V8.09499L5.80505 7.29099V5.50799L7.50005 4.05099L9.19705 5.50799V7.29099L7.78205 8.09499V8.91999L9.21805 9.96599V13.422L7.89205 14.4V15.138L9.84805 16.377Z"
                          fill="white"
                        />
                        <path
                          d="M13.8919 15.422H10.9949L8.68091 13.658L8.93291 13.326L11.1349 15.005H13.8919V15.422Z"
                          fill="white"
                        />
                        <path
                          d="M14.3471 15.844C14.7071 15.844 15.0001 15.552 15.0001 15.191C15.0001 14.83 14.7081 14.538 14.3471 14.538C13.9861 14.538 13.6941 14.83 13.6941 15.191C13.6941 15.552 13.9861 15.844 14.3471 15.844Z"
                          fill="white"
                        />
                        <path
                          d="M8.80012 9.86202L8.54712 9.53202L10.9951 7.65802H13.8921V8.07402H11.1361L8.80012 9.86202Z"
                          fill="white"
                        />
                        <path
                          d="M14.3471 8.54002C14.7071 8.54002 15.0001 8.24802 15.0001 7.88702C15.0001 7.52602 14.7081 7.23502 14.3471 7.23502C13.9861 7.23502 13.6941 7.52702 13.6941 7.88702C13.6941 8.24702 13.9861 8.54002 14.3471 8.54002Z"
                          fill="white"
                        />
                        <path
                          d="M12.043 11.418H9.21704V11.833H12.043V11.418Z"
                          fill="white"
                        />
                        <path
                          d="M12.2709 12.28C12.6309 12.28 12.9239 11.988 12.9239 11.627C12.9239 11.266 12.6319 10.974 12.2709 10.974C11.9099 10.974 11.6189 11.266 11.6189 11.627C11.6189 11.988 11.9109 12.28 12.2709 12.28Z"
                          fill="white"
                        />
                        <path
                          d="M8.98307 5.534L8.79907 5.161L10.6181 4.263V1.37H11.0341V4.52L8.98407 5.534H8.98307Z"
                          fill="white"
                        />
                        <path
                          d="M10.8271 1.80501C11.1871 1.80501 11.4801 1.51301 11.4801 1.15301C11.4801 0.793007 11.1881 0.501007 10.8271 0.501007C10.4661 0.501007 10.1741 0.793007 10.1741 1.15301C10.1741 1.51301 10.4661 1.80501 10.8271 1.80501Z"
                          fill="white"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_1_56">
                        <rect
                          width="15"
                          height="20.14"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </SvgIcon>
              </ListItemIcon>
              <ListItemText primary="CipherAnts" />
            </SubListItem>
          </List>
        </Collapse>
        {[{ name: "Insights", icon: <LeaderboardRoundedIcon /> }].map(
          (item) => (
            <ListItemButton
              key={item.name}
              sx={{
                "& .MuiListItemIcon-root": {
                  minWidth: "30px !important",
                  height: "50px",
                  alignItems: "center",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  color: "#fff",
                  "& .MuiSvgIcon-root": {
                    fontSize: "18px",
                  },
                }}
              >
                {item.icon}
              </ListItemIcon>
              <CustomText sx={{}}>{item.name}</CustomText>
            </ListItemButton>
          )
        )}

        <ListItemButton>
          <ListItemText
            sx={{
              color: "#fff",
              "& .MuiTypography-root": { fontSize: "8px" },
            }}
            primary="Settings"
          />
        </ListItemButton>
        {[{ name: "Settings", icon: <SettingsRoundedIcon /> }].map((item) => (
          <ListItemButton
            onClick={hanldeOpenSettings}
            key={item.name}
            sx={{
              "& .MuiListItemIcon-root": {
                minWidth: "30px !important",
              },
            }}
          >
            <ListItemIcon
              sx={{
                color: "#fff",
                "& .MuiSvgIcon-root": {
                  fontSize: "18px",
                },
              }}
            >
              {item.icon}
            </ListItemIcon>
            <CustomText sx={{}}>{item.name}</CustomText>
          </ListItemButton>
        ))}
        <Collapse in={openSettings} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <SubListItem sx={{ pl: 4 }}>
              <ListItemIcon
                sx={{
                  color: "#fff",
                  "& .MuiSvgIcon-root": {
                    fontSize: "18px",
                  },
                }}
              >
                <DataSaverOnIcon />
              </ListItemIcon>
              <ListItemText primary="Create Roles" />
            </SubListItem>
            <SubListItem sx={{ pl: 4 }}>
              <ListItemIcon
                sx={{
                  color: "#fff",
                  "& .MuiSvgIcon-root": {
                    fontSize: "18px",
                  },
                }}
              >
                <PersonAddIcon />
              </ListItemIcon>
              <ListItemText primary="Create Users" sx={{}} />
            </SubListItem>
          </List>
        </Collapse>
      </List>
      <List>
        <ListItemButton
          sx={{
            "& .MuiListItemIcon-root": {
              minWidth: "30px !important",
              fontSize: "15px",
            },
          }}
        >
          <ListItemIcon
            sx={{
              color: "#fff",
              "& .MuiSvgIcon-root": {
                fontSize: "18px",
              },
            }}
          >
            <PersonIcon />
          </ListItemIcon>
          <CustomText sx={{}}>Profile</CustomText>
        </ListItemButton>
        <ListItemButton
          //   onClick={() => handleLogout()}
          sx={{
            "& .MuiListItemIcon-root": {
              minWidth: "30px !important",
            },
          }}
        >
          <ListItemIcon
            sx={{
              color: "#fff",
              "& .MuiSvgIcon-root": {
                fontSize: "18px",
              },
            }}
          >
            <LogoutIcon />
          </ListItemIcon>
          <CustomText sx={{}}>Logout</CustomText>
        </ListItemButton>
      </List> */}
      </>
   );
}

export default SidebarList;
