import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/dashboard/Sidebar";
import InsightsComponent from "../../../components/reports/Insights/InsightsComponent";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getInsights } from "../../../store/slices/insightsSlice";

function Insights() {
   const dispatch = useDispatch();
   const { reports } = useSelector((state) => state.insights);

   useEffect(() => {
      dispatch(getInsights({ startDate: "", endDate: "" }));
   }, []);

   return (
      <div style={{ display: "flex" }}>
         <Sidebar />
         <InsightsComponent reports={reports} />
      </div>
   );
}

export default Insights;
