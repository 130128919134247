import API from "../config/axios";

export const createChat = async (data) => {
   try {
      const response = await API.post(`/chats`, data);
      return response.data;
   } catch (err) {
      console.log(err);
      return err;
   }
};
