import React, { useEffect, useState } from "react";
import Login from "../../components/Login/Login";
import { useNavigate } from "react-router-dom";
import OtpEmail from "../../components/Login/OtpEmail";

function LoginPage() {
   const navigate = useNavigate();
   useEffect(() => {
      const isLogin = JSON.parse(localStorage.getItem("login"));

      if (isLogin) {
         navigate("/dashboard");
      }
   }, []);
   return <Login/>
}

export default LoginPage;
