import React, { useEffect } from "react";
import ReportTable from "../../../components/reports/bulkSendReport/ReportTable";
import Sidebar from "../../../components/dashboard/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { getBulkSendReport } from "../../../store/slices/bulkSendReportSlice";

function BulkSendReport() {
  const dispatch = useDispatch();
  const { loading, reports } = useSelector((state) => state.bulkSend);

  useEffect(() => {
    dispatch(getBulkSendReport());

  }, []);
  return (
    <div style={{ display: "flex" }}>
      <Sidebar />
      <ReportTable reports={reports}/>
    </div>
  );
}

export default BulkSendReport;
