import React, { useState } from "react";
import OtpEmail from "../../components/Login/OtpEmail";
import VarifyOTP from "../../components/Login/VerifyOtp";

function ForgotPassword() {
   const [isOtpSend, setIsOtpSend] = useState(true);

   if (isOtpSend) return <VarifyOTP setIsOtpSend={setIsOtpSend}/>;
   else return <OtpEmail setIsOtpSend={setIsOtpSend}/>;
}

export default ForgotPassword;
