import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import BulkTemplateViewModal from "../BulkTemplateViewModel";
import StepperListContact from "./StepperListContact";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { getAllChats } from "../../../store/slices/chatSlice";
import { useDispatch } from "react-redux";

const steps = ["Select a template", "Add values", "Select contacts"];

export default function HorizontalLinearStepper({ templates, allContacts, handleClose }) {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedTemplates, setSelectedTemplates] = useState({});
  const [newTemplateData, setNewTemplateData] = useState({});
  const [selectedContacts, setSelectedContacts] = useState([]);

  const dispatch = useDispatch();

  const handleSelectTemplate = (template) => {
    setSelectedTemplates(template);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBulkSendTemplate = async () => {
    try {
      newTemplateData.append("contacts", JSON.stringify(selectedContacts));
      const response = await axios.post(`${process.env.REACT_APP_API}/messages/bulk-send`, newTemplateData);
      if (response && response?.data?.status === "success") {
        setSelectedTemplates({});
        setSelectedContacts([]);
        setNewTemplateData({});
        dispatch(getAllChats());

        handleClose();
        toast.success("Message send successfully");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setNewTemplateData({});
    setSelectedContacts([]);
    setSelectedTemplates({});
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Toaster />
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
            <Button onClick={() => handleBulkSendTemplate()}>Send</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>

          {activeStep === 0 && (
            <Box>
              {templates?.map((template) => (
                <Typography
                  sx={{
                    ":hover": {
                      color: "black",
                      cursor: "pointer",
                      fontWeight: "500",
                    },
                  }}
                  onClick={() => handleSelectTemplate(template)}
                >
                  {template.name}
                </Typography>
              ))}
            </Box>
          )}
          {activeStep === 1 && (
            <BulkTemplateViewModal selectedTemplate={selectedTemplates} setNewTemplateData={setNewTemplateData} handleNext={handleNext} />
          )}
          {activeStep === 2 && (
            <StepperListContact allContacts={allContacts} selectedContacts={selectedContacts} setSelectedContacts={setSelectedContacts} />
          )}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />

            {activeStep === steps.length - 1 && <Button onClick={handleNext}>Finish</Button>}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
