import axios from "axios";
import { date } from "yup";
import API from "../config/axios";

export const sentOptToEmail = async (data) => {
   try {
      const response = await API.post(`/user/send-otp`, data);
      return response.data;
   } catch (err) {
      console.log(err);
      return err;
   }
};

export const userVerifyOTP = async (data) => {
   try {
      const response = await API.post(`/user/verify-otp`, data);
      return response.data;
   } catch (err) {
      console.log(err);
      return err;
   }
};

export const resetPassword = async (data) => {
   try {
      const response = await API.post(`/user/reset-password`, data);
      return response.data;
   } catch (err) {
      console.log(err);
      return err;
   }
};

export const getAdminDetails = async () => {
   try {
      const { token } = JSON.parse(localStorage.getItem("admin-auth"));
      const res = await API.get(`/admin/admin-data`);

      return res.data;
   } catch (err) {
      console.log(err);
   }
};
