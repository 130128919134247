import React from "react";
import ListMessage from "./ListMessage";
import InteractiveButtonMessage from "./InteractiveButtonMessage";

function InteractiveMessage({ content }) {
   const { type } = content?.message?.interactive||""
   return (
      <div>
         {(type === "list" || type === "list_reply") && (
            <ListMessage content={content} />
         )}
         {(type === "button" || type === "button_reply") && (
            <InteractiveButtonMessage content={content} />
         )}
         {type === "cta_url" && <InteractiveButtonMessage content={content} />}
      </div>
   );
}

export default InteractiveMessage;
