import { Box, Typography, styled } from "@mui/material";
import React from "react";
import BackgroundImg from "../../assets/images/whatsappBackground.png";

const Preview = styled(Box)({
  height: "300px",
  background: `url(${BackgroundImg})`,
  width: "100%",
  marginTop:"10px",
  backgroundSize:"cover"

  
});

function PreviewBox() {
  return (
    <Box display="flex" justifyContent="center">
      <Box m={2} width="100%" border="1px solid #00ff7b0f" bgcolor="#00ff7b0f" p={2}>
        <Typography>Preview</Typography>
        <Preview>dsfdf</Preview>
      </Box>
    </Box>
  );
}

export default PreviewBox;
