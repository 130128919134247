import React, { useEffect } from "react";
import Sidebar from "../../components/dashboard/Sidebar";
import LabelTable from "../../components/setttings/conversation/LabelTable";
import { useDispatch, useSelector } from "react-redux";
import { getAllLabels } from "../../store/slices/labelSlice";

function ConverSationLabels() {
   const dispatch = useDispatch();
   const { labels } = useSelector((state) => state.labels);

   useEffect(() => {
      dispatch(getAllLabels());
   }, []);
   return (
      <div style={{ display: "flex" }}>
         <Sidebar />
         <LabelTable labels={labels} />
      </div>
   );
}

export default ConverSationLabels;
