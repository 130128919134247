import {
   Box,
   Button,
   Checkbox,
   List,
   ListItem,
   ListItemButton,
   CircularProgress,
   ListItemIcon,
   ListItemText,
   Typography,
   styled,
   Tabs,
   Tab,
} from "@mui/material";
import React, { useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import toast, { ToastBar, Toaster } from "react-hot-toast";
import Papa from "papaparse";
import { handleDownload } from "../../../constant/helperFunctions";

const VisuallyHiddenInput = styled("input")({
   clip: "rect(0 0 0 0)",
   clipPath: "inset(50%)",
   height: 1,
   overflow: "hidden",
   position: "absolute",
   bottom: 0,
   left: 0,
   whiteSpace: "nowrap",
   width: 1,
});

function StepperListContact({
   allContacts,
   selectedContacts,
   setSelectedContacts,
}) {
   const [loading, setLoading] = useState(false);
   const [value, setValue] = React.useState("one");

   const handleChange = (event, newValue) => {
      setValue(newValue);
      setSelectedContacts([]);
   };

   const handleFileChange = (event) => {
      // Handle selected file(s) here
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
         const text = event.target.result;
         let parsedData = Papa.parse(text, { header: true }).data;
         parsedData = parsedData.filter((item) => item.Contacts);
         setSelectedContacts(parsedData.map((contact) => contact.Contacts));
      };

      reader.readAsText(file);

      // handleFileUpload(file);
   };

   const handleToggle = (value) => () => {
      const currentIndex = selectedContacts.indexOf(value);
      const newChecked = [...selectedContacts];

      if (currentIndex === -1) {
         newChecked.push(value);
      } else {
         newChecked.splice(currentIndex, 1);
      }

      setSelectedContacts(newChecked);
   };
   return (
      <>
         <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
         >
            <Tab value="one" label="Upload csv" />
            <Tab value="two" label="Select from Contact list" />
         </Tabs>

         <Toaster />
         {value === "one" && (
            <Box mt={3}>
               {selectedContacts.length > 0 ? (
                  <Box>
                     {selectedContacts.slice(0, 5).map((contact) => (
                        <>
                           <Typography> {contact}</Typography>
                           <hr />
                        </>
                     ))}
                     {selectedContacts.length > 5 && (
                        <Typography>...</Typography>
                     )}
                     <Typography variant="body1" color="initial">
                        Total contacts: {selectedContacts.length}
                     </Typography>
                  </Box>
               ) : (
                  <>
                     <Button
                        sx={{
                           backgroundColor: "#0F61A5",
                           borderRadius: "10px",
                        }}
                        component="label"
                        role={undefined}
                        variant="contained"
                        disabled={loading ? true : false}
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon />}
                     >
                        {loading ? (
                           <>
                              {"Uploading.. "}
                              <CircularProgress
                                 style={{
                                    height: "24px",
                                    width: "24px",
                                    color: "grey",
                                 }}
                              />
                           </>
                        ) : (
                           "UPLOAD .CSV FILE"
                        )}
                        <VisuallyHiddenInput
                           type="file"
                           accept={".csv"}
                           onChange={handleFileChange}
                        />
                     </Button>
                     <Button
                        variant="text"
                        color="primary"
                        onClick={() => handleDownload()}
                     >
                        Download Sample
                     </Button>
                  </>
               )}
            </Box>
         )}
         {value === "two" && (
            <>
               <List
                  sx={{
                     width: "100%",
                     maxWidth: 360,
                     bgcolor: "background.paper",
                  }}
               >
                  {allContacts?.map((value) => {
                     const labelId = `checkbox-list-label-${value}`;

                     return (
                        <ListItem key={value.phone_number} disablePadding>
                           <ListItemButton
                              role={undefined}
                              onClick={handleToggle(value.phone_number)}
                              dense
                           >
                              <ListItemIcon>
                                 <Checkbox
                                    edge="start"
                                    checked={
                                       selectedContacts.indexOf(
                                          value.phone_number
                                       ) !== -1
                                    }
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                       "aria-labelledby": value.phone_number,
                                    }}
                                 />
                              </ListItemIcon>
                              <ListItemText
                                 id={labelId}
                                 primary={value.phone_number}
                              />
                           </ListItemButton>
                        </ListItem>
                     );
                  })}
               </List>
            </>
         )}
      </>
   );
}

export default StepperListContact;
