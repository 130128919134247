import React, { useState } from "react";
import {
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Paper,
   IconButton,
   Typography,
   styled,
   Box,
   Switch,
   Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import toast, { Toaster } from "react-hot-toast";
import { deleteLabel, editLabel } from "../../../services/labelServide";
import { getAllLabels } from "../../../store/slices/labelSlice";
import { useDispatch } from "react-redux";
import AddLabel from "./AddLabel";
import WarningModal from "../../common/WarningModal";

const OuterBox = styled(Box)({
   width: "100%",
   height: "auto",
   borderTop: "1px solid grey",
});

const rows = [
   { id: 1, category: "Books", status: "Active" },
   { id: 2, category: "Electronics", status: "Inactive" },
   { id: 3, category: "Clothing", status: "Active" },
];

const LabelTable = ({ labels }) => {
   const [checked, setChecked] = useState(false);
   const [openAddLabel, setOpenAddLabel] = useState(false);
   const [openWarningModal, setOpenWarningModal] = useState(false);
   const [selectedLabelId, setSelectedLabelId] = useState("");
   const dispatch = useDispatch();

   const handleChange = async (labelId, status) => {
      let newStatus = status === "active" ? "inactive" : "active";
      const response = await editLabel(labelId, newStatus);
      if (response && response.status === "success") {
         dispatch(getAllLabels());
      }
   };
   // Function to delete label
   const handelDeleteLabel = async () => {
      const response = await deleteLabel(selectedLabelId);
      if (response && response.status === "success") {
         toast.success("Deleted successfully");
         setOpenWarningModal(false);
         dispatch(getAllLabels());
      }
   };

   const handleOpenWaringModal = (labelId) => {
      setSelectedLabelId(labelId);
      setOpenWarningModal(true);
   };
   const getStatusDot = (status) => {
      if (status === "Active") {
         return <span style={{ color: "green", marginRight: "4px" }}>●</span>;
      } else if (status === "Inactive") {
         return <span style={{ color: "red", marginRight: "4px" }}>●</span>;
      }
      return null;
   };
   return (
      <OuterBox mt={8} ml={5} mr={4}>
         <Toaster />
         <Typography fontSize="20px" fontWeight="600" mt={2}>
            Conversation Labels
         </Typography>
         <Box
            height={50}
            width="100%"
            display="flex"
            alignItems="end"
            justifyContent="end"
         >
            <Button
               sx={{ color: "black", borderColor: "black" }}
               variant="outlined"
               startIcon={<AddIcon />}
               onClick={() => setOpenAddLabel(true)}
            >
               Create
            </Button>
         </Box>
         <TableContainer component={Paper} sx={{ marginTop: "30px" }}>
            <Table>
               <TableHead>
                  <TableRow>
                     <TableCell>Category</TableCell>
                     <TableCell>Status</TableCell>
                     <TableCell>Color</TableCell>
                     <TableCell>Change Status</TableCell>
                     <TableCell>Delete</TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {labels.map((row) => (
                     <TableRow key={row._id}>
                        <TableCell>{row.labelCategory}</TableCell>
                        <TableCell>
                           {getStatusDot(row.status)}
                           {row.status}
                        </TableCell>
                        <TableCell>
                           <Box
                              height={20}
                              width={50}
                              bgcolor={row?.color}
                           ></Box>
                        </TableCell>
                        <TableCell>
                           <IconButton aria-label="edit">
                              <Switch
                                 checked={
                                    row?.status === "active" ? true : false
                                 }
                                 onChange={() =>
                                    handleChange(row._id, row?.status)
                                 }
                                 inputProps={{ "aria-label": "controlled" }}
                              />
                           </IconButton>
                        </TableCell>
                        <TableCell>
                           <IconButton
                              aria-label="delete"
                              onClick={() => handleOpenWaringModal(row._id)}
                           >
                              <DeleteIcon />
                           </IconButton>
                        </TableCell>
                     </TableRow>
                  ))}
               </TableBody>
            </Table>
         </TableContainer>
         {openAddLabel && (
            <AddLabel
               openAddLabel={openAddLabel}
               setOpenAddLabel={setOpenAddLabel}
            />
         )}
         <WarningModal
            open={openWarningModal}
            modelName="Delete Label"
            modelContent="Are you sure you want to delete Label? This action cannot be undone."
            setOpen={setOpenWarningModal}
            fun={handelDeleteLabel}
         />
      </OuterBox>
   );
};

export default LabelTable;
