import * as React from "react";
import Drawer from "@mui/material/Drawer";
import SidebarList from "./SidebarList";
import { useDispatch, useSelector } from "react-redux";
import { setSidebar } from "../../store/slices/sideBarSlice";

export default function DrawerSidebar() {
  const dispatch = useDispatch();

  const toggleDrawer = () => {
    dispatch(setSidebar());
  };
  const openDrawer = useSelector((state) => state.sidebar);

  return (
    <>
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={()=>toggleDrawer()}
        backgroundColor="black"
        sx={{
          color: "black",
          "& .MuiModal-root-MuiDrawer-root .MuiPaper-root": {
            backgroundColor: "black",
          },
          "& .MuiPaper-root": { width: "230px", backgroundColor: "black" },
        }}
      >
        <SidebarList />
      </Drawer>
    </>
  );
}
