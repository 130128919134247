import React, { useEffect, useState } from "react";
import UserSection from "./UserSection";
import Chat from "./Chat";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import { getAllChats } from "../../store/slices/chatSlice";
import API from "../../config/axios";

function ChatMainContainer() {
   const dispatch = useDispatch();
   const [profile, setProfile] = useState({});

   const { messages, selectedChat } = useSelector((state) => state.messages);
   const { chats } = useSelector((state) => state.chat);

   const getBusinessProfile = async () => {
      try {
         const response = await API.get(`/user/business_profile`);
         setProfile(response.data.data[0]);
      } catch (err) {
         console.log(err);
      }
   };

   useEffect(() => {
      dispatch(getAllChats());
      getBusinessProfile();
   }, []);

   // const getAllMessagesByPagination = async (chatId, page) => {
   //   try {
   //     const response = await axios.get(
   //       `${process.env.REACT_APP_API}/messages/${chatId}?page=${page}`
   //     );
   //     setMessages(response.data.response);
   //   } catch (err) {
   //     console.log(err);
   //   }
   // };

   // useEffect(() => {
   //   if (
   //     newReceicedMessage &&
   //     newReceicedMessage.chat_id === selectedChat?._id
   //   ) {
   //     dispatch(updateNewMessage(newReceicedMessage));
   //   }

   //   if (newChat) {
   //     // setChats([...chats, newChat]);
   //     dispatch(updateNewChat(newChat));
   //   }
   // }, [newReceicedMessage, newChat]);
   return (
      <Grid container ml="5px" mr="5px" mb={10} sx={{ height: "100%" }}>
         <Grid
            item
            xs={12}
            md={4}
            sx={{
               backgroundColor: "green",
               borderRadius: "15px",
               height: "100%",
            }}
         >
            <UserSection
               profileData={profile}
               chats={chats}
               // getAllMessagesByChatId={handleGetAllMessagesByChatId}
               // getAllChats={getAllChats}
            />
         </Grid>
         <Grid
            item
            xs={12}
            md={8}
            sx={{
               position: "relative",
               width: "100%",
               height: "100%",
               // height: "auto",
               // overflow: "hidden",
            }}
         >
            <Chat
               messages={messages}
               selectedChat={selectedChat}
               // setMessages={setMessages}
            />
         </Grid>
      </Grid>
   );
}

export default ChatMainContainer;
