import { Box, Dialog, TextField } from "@mui/material";
import React, { useState } from "react";

function NewChatModal({ open, handleClose }) {
  const [contact, setContact] = useState("");
  const handleChange = (e) => {
    setContact(e.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box height="200px" width="700" bgcolor="#fff" p={2}>
        <TextField
          id=""
          label="Contact"
          value={contact}
          onChange={handleChange}
        />
      </Box>
    </Dialog>
  );
}

export default NewChatModal;
