import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

function TemplatePreviewModal({
  open,
  handleClose,
  bodyText,
  examples,
  setExamples,
  handleCreateTemplate,
  setIsVariableExist
}) {
  const [bodyParams, setBodyParams] = useState([]);

  const handleChange = (e, id) => {
    const result = examples?.map((item) => {
      if (item.id === id) {
        item.text = e.target.value;
      }
      return item;
    });

    setExamples(result);
  };

  useEffect(() => {
    if (!bodyText) return;

    const text = bodyText || "";
    const regex = /\{\{\d+\}\}/g;

    // Use match() to find all occurrences of the pattern in the text
    let matches = text.match(regex) || [];
    if (matches.length > 0) {
      let examples = [];
      matches = matches.forEach((item) => {
        examples.push({ id: item, type: "body_text", text: "" });
      });
      setExamples(examples);
      setIsVariableExist(true)
    }
  }, [bodyText]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Template Preview</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="body1" color="initial">
            {bodyText}
          </Typography>
          <Box>
            {examples?.map((params) => (
              <TextField
              
                sx={{ mt: "10px" }}
                key={params?.id}
                fullWidth
                id=""
                label={`Body ${params?.id}`}
                value={examples?.find((item) => item?.id === params?.id)?.text}
                onChange={(e) => handleChange(e, params?.id)}
              />
            ))}
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateTemplate}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TemplatePreviewModal;
