import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../config/axios";

export const getAllChats = createAsyncThunk("chat/getAllChats", async () => {
   try {
      const response = await API.get(`/chats`);

      return response.data?.result;
   } catch (err) {
      console.log(err);
   }
});

const initialState = {
   chats: [],
   searchResult: [],
   loading: false,
   error: null,
   clicked: false,
   totalNotification: 0,
   newAddedChat: {},
   totalChatCount: 0,
};

const chatSlice = createSlice({
   name: "chat",
   initialState,
   reducers: {
      addNewAddedChat(state, action) {
         state.newAddedChat = action.payload;
      },
      setNewChatEmpty(state) {
         state.newAddedChat = {};
      },
      removeNewAddedChat(state) {
         state.newAddedChat = {};
      },
      updateNewChat(state, action) {
         state.chats = [...state.chats, action.payload];
      },
      setSearchedChat(state, action) {
         const searchTerm = action.payload.toLowerCase(); // Convert search term to lowercase

         state.searchResult = state.chats.filter((chatObject) => {
            const hasNameMatch = chatObject.chat.name
               ?.toLowerCase()
               .includes(searchTerm);
            const hasPhoneNumberMatch =
               chatObject.chat.phone_number?.includes(searchTerm);

            // Return the chat if either name or phone number matches
            return hasNameMatch || hasPhoneNumberMatch;
         });
      },
      updateTotalNotification(state, action) {
         let total = 0;
         state.chats?.forEach((item) => {
            if (item.messageCount > 0) {
               total = total + 1;
            }
         });
         state.totalNotification = total;
      },
      updateNewMessageCount(state, action) {
         const { chatId, count } = action.payload;
         const updatedChat = state.chats?.map((chatData) => {
            const { _id } = chatData.chat;
            if (_id === chatId) {
               chatData.messageCount = count;
            }
            return chatData;
         });
         state.chats = updatedChat;
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(getAllChats.pending, (state) => {
            state.loading = true;
         })
         .addCase(getAllChats.fulfilled, (state, action) => {
            state.loading = false;
            state.chats = action.payload;
            state.searchResult = action.payload;
            let total = 0;
            action.payload?.forEach((item) => {
               if (item.messageCount > 0) {
                  total = total + 1;
               }
            });
            state.totalNotification = total;
         })
         .addCase(getAllChats.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
         });
   },

   // reducers: {
   //   saveSelectedChat(state, action) {
   //     return {
   //       ...state,
   //       selectedChat: action.payload,
   //     };
   //   },
   // },
});

export default chatSlice.reducer;

export const {
   updateNewChat,
   updateNewMessageCount,
   updateTotalNotification,
   addNewAddedChat,
   setNewChatEmpty,
   removeNewAddedChat,
   setSearchedChat,
} = chatSlice.actions;
