import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
   Box,
   Button,
   Container,
   CssBaseline,
   TextField,
   ThemeProvider,
   createTheme,
} from "@mui/material";

import * as authService from "../../services/authService";
import toast from "react-hot-toast";

function ResetPasswordForm() {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const { userId } = useSelector((state) => state.user);

   const theme = createTheme();
   // otp login form schema
   const schema = yup.object().shape({
      password: yup
         .string()
         .min(6, "Password must be at least 6 characters")
         .required("Password is required"),
      confirmPassword: yup
         .string()
         .oneOf([yup.ref("password"), null], "Passwords must match") // Compare with newPassword
         .required("Confirm password is required"),
   });
   // setting schema
   const {
      register,
      handleSubmit,
      formState: { errors },
   } = useForm({
      resolver: yupResolver(schema),
   });
   // form on submit function
   const onSubmit = async (data) => {

      const response = await authService.resetPassword({ ...data, userId });
      if (response.status === "success") {
         toast.success("Password reset successfully");
         setTimeout(() => {
            navigate("/");
         }, 2000);
      } else {
         toast.error("Password reset failed");
      }
   };
   return (
      <ThemeProvider theme={theme}>
         <Container ba>
            <Container component="main" maxWidth="xs">
               <CssBaseline />
               <Box
                  sx={{
                     display: "flex",
                     marginTop: "25vh",
                     justifyContent: "center",
                     flexDirection: "column",
                     alignItems: "center",
                     backgroundColor: "white",
                     padding: "20px",
                     boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  }}
               >
                  <p>
                     <b> Reset Password</b>
                  </p>
                  {/* <div
                     style={{
                        width: "400px",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        fontSize: "14px",
                     }}
                  >
                     <p>
                        We will send you an
                        <b> One Time Password</b>
                        on this email address
                     </p>
                  </div> */}
                  <Box
                     component="form"
                     onSubmit={handleSubmit(onSubmit)}
                     noValidate
                     sx={{ height: "300px" }}
                  >
                     <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="newPassword"
                        label="New Password"
                        name="password"
                        autoComplete="password"
                        autoFocus
                        color="secondary"
                        error={!!errors.password}
                        helperText={
                           errors.password ? errors.password.message : ""
                        }
                        {...register("password")}
                     />
                     <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="conf-passowrd"
                        label="Confirm Password"
                        name="confirmPassword"
                        autoComplete="confirmPassword"
                        autoFocus
                        color="secondary"
                        error={!!errors.confirmPassword}
                        helperText={
                           errors.confirmPassword
                              ? errors.confirmPassword.message
                              : ""
                        }
                        {...register("confirmPassword")}
                     />

                     <Button
                        sx={{ mt: 3 }}
                        className="login-btn"
                        type="submit"
                        fullWidth
                        variant="contained"
                     >
                        Send
                     </Button>
                     <Box
                        sx={{
                           textAlign: "center",
                           cursor: "pointer",
                           marginBottom: "20px",
                        }}
                     >
                        <p onClick={() => navigate("/")}>Cancel</p>
                     </Box>
                  </Box>
               </Box>
            </Container>
         </Container>
      </ThemeProvider>
   );
}

export default ResetPasswordForm;
