import { Box, Typography, styled } from "@mui/material";
import React from "react";
import ChatMainContainer from "./ChatMainContainer";
import Footer from "../dashboard/Footer";

const TitleBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "15px",
  margin: "15px 5px 15px 5px",
});

function WhatsappContainer() {
  return (
    <Box
      p={5}
      mt={3}
      sx={{
        backgroundColor: "#e0e3e7",
        // backgroundColor: "red",
        // paddingBottom: "25px",
        width: "100%",

        // minHeight: "80vh",
        height: "80vh",
        // height:"100%"
      }}
    >
      <TitleBox>
        {/* <Typography fontSize="20px" fontWeight="600">
          Whatsapp
        </Typography>
        <Typography fontSize="10px">Dashboard/ inbox / Whatsapp</Typography> */}
      </TitleBox>
      {/* no need to pass from here set directly to store */}
      <ChatMainContainer
        // newReceicedMessage={newReceicedMessage}
        // newChat={newChat}
      />
    </Box>
  );
}

export default WhatsappContainer;
