import "./App.css";
// import the library
import { library } from "@fortawesome/fontawesome-svg-core";

// import your icons
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import Body from "./routes/index";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme/Typography";
import { Provider } from "react-redux";
import appStore from "./store/appStore";


function App() {
 
  return (
    <ThemeProvider theme={theme}>
      <Provider store={appStore}>
        <div className="App">
          <Body />
        </div>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
library.add(fab, fas, far);
