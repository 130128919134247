import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
   Box,
   Button,
   Chip,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Typography,
   styled,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import API from "../../config/axios";

const MainBox = styled(Box)({
   borderRadius: "15px",
   backgroundColor: "#fff",
   //   padding: '25px',
   overflowY: "scroll",
});
const OuterBox = styled(Box)({
   width: "100%",
   height: "auto",
   borderTop: "1px solid grey",
});

function Templates() {
   const [templates, setTemplates] = useState([]);

   const getAllTemplates = async () => {
      try {
         const response = await API.get(`/template`);

         if (response && response?.data?.templates) {
            const { templates } = response.data;
            setTemplates(templates.data);
         }
      } catch (err) {
         console.log(err);
      }
   };

   const formatTemplate = () => {};

   useEffect(() => {
      getAllTemplates();
   }, []);
   return (
      <OuterBox mt={8} ml={5} mr={4}>
         <Toaster />
         <Typography fontSize="20px" fontWeight="600" mt={2}>
            Templates
         </Typography>
         <MainBox mt={2}>
            <TableContainer>
               <Table>
                  <TableHead
                     sx={{
                        background: "#8EB7FF",
                        color: "#fff",
                        height: "56px",
                     }}
                  >
                     <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Preview</TableCell>
                        <TableCell>
                           Sync Status <br />
                           (Meta)
                        </TableCell>
                        <TableCell>Languages</TableCell>
                        <TableCell></TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {templates?.map((template) => (
                        <TableRow
                           key={template?.id}
                           sx={{
                              "& .MuiTableCell-root": {
                                 border: "1px solid #8080803d",
                              },
                           }}
                        >
                           <TableCell>{template?.name}</TableCell>
                           <TableCell>{template?.category}</TableCell>
                           <TableCell>
                              <Box>
                                 <Typography fontSize="13px">
                                    Header:
                                    {
                                       template?.components?.find(
                                          (comp) => comp.type === "HEADER"
                                       )?.text
                                    }
                                 </Typography>
                                 <Typography fontSize="13px">
                                    Body:
                                    {
                                       template?.components?.find(
                                          (comp) => comp.type === "BODY"
                                       )?.text
                                    }
                                 </Typography>
                                 <Typography fontSize="13px">
                                    Footer:
                                    {
                                       template?.components?.find(
                                          (comp) => comp.type === "FOOTER"
                                       )?.text
                                    }
                                 </Typography>
                              </Box>
                           </TableCell>
                           <TableCell>
                              <Button
                                 variant="outlined"
                                 sx={{
                                    color:
                                       template?.status === "REJECTED"
                                          ? "red"
                                          : "green",
                                    borderColor:
                                       template?.status === "REJECTED"
                                          ? "red"
                                          : "green",
                                    textTransform: "capitalize",
                                 }}
                              >
                                 {template?.status}
                              </Button>
                           </TableCell>
                           <TableCell align="center">
                              <Chip
                                 label={template?.language}
                                 sx={{
                                    color: "white",
                                    backgroundColor: "green",
                                 }}
                              />
                           </TableCell>
                           {/* <TableCell width="50px">
                              <Box
                                 display="flex"
                                 justifyContent="space-between"
                              >
                                 <FontAwesomeIcon
                                    icon="fa-regular fa-pen-to-square"
                                    style={{ cursor: "pointer" }}
                                 />
                                 <FontAwesomeIcon
                                    icon="fa-regular fa-copy"
                                    style={{ cursor: "pointer" }}
                                 />
                              </Box>
                              <Box
                                 display="flex"
                                 justifyContent="space-between"
                                 mt={2}
                              >
                                 <FontAwesomeIcon
                                    icon="fa-solid fa-globe"
                                    style={{ cursor: "pointer" }}
                                 />
                                 <FontAwesomeIcon
                                    icon="fa-solid fa-trash-can"
                                    style={{ cursor: "pointer" }}
                                 />
                              </Box>
                           </TableCell> */}
                        </TableRow>
                     ))}
                  </TableBody>
               </Table>
            </TableContainer>
         </MainBox>
      </OuterBox>
   );
}

export default Templates;
