import axios from "axios";

const API = axios.create({
   baseURL: process.env.REACT_APP_API,
});

API.interceptors.request.use(
   async (config) => {
      const token = localStorage.getItem("token");
      if (token && !config.url.endsWith("/signIn")) {
         // Exclude token for login endpoint
         config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
   },
   async (error) => {
      return Promise.reject(error);
   }
);

API.interceptors.response.use(
   (response) => response,
   (error) => {
      console.log(error.response);
      if (error.response && error.response.status === 401) {
         const currentUrl = error.config.url; // Get the current request URL

         // Check if the request URL is not the login endpoint
         if (!currentUrl.endsWith("/signIn")) {
            // Clear the user and token from the Redux store
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            localStorage.removeItem("login");
            //   localStorage.removeItem("isAuthorized");

            // Redirect to the login page or perform any other necessary actions
            window.location.href = "/";
         }
      }
      return Promise.reject(
         (error.response && error.response.data) || "Something went wrong"
      );
   }
);

export default API;
