import React, { useEffect } from "react";
import ImportContacts from "../../components/contacts/ImportContacts";
import { Box } from "@mui/material";
import Footer from "../../components/dashboard/Footer";
import Sidebar from "../../components/dashboard/Sidebar";
import { useNavigate } from "react-router-dom";

function Contacts() {
  const navigate = useNavigate();
  useEffect(() => {
    const isLogin = JSON.parse(localStorage.getItem("login"));

    if (!isLogin) {
      navigate("/");
    }
  }, []);
  return (
    <>
      <Box display="flex">
        <Sidebar />
        <ImportContacts />;
      </Box>
      <Footer />
    </>
  );
}

export default Contacts;
