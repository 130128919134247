import { Avatar, Box, Button, Typography, styled } from "@mui/material";
import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";

// const ProfBox = styled(Box)({
//   position: "absolute",
//   borderRadius: "15px",
//   top: 0,
//   height: "100%",
//   width: "100%",
//   backgroundColor: "#212529",
//   transition: "transform 1s ease-out",
//   transform: "translateX(-100)",
// });

function Profile({ active, setOpenProfile }) {
  const profile = {
    position: "absolute",
    borderRadius: "15px",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    backgroundColor: "white",
    transition: "transform 0.5s ease-out",
    transform: "translateX(-100%)",
  };
  const activeProfile = {
    transform: "translateX(0%)" /* slide it into view */,
  };
  return (
    <Box sx={{ ...(active ? { ...profile, ...activeProfile } : profile) }}>
      <Box
        mt={3}
        ml={2}
        mr={2}
        sx={{
          height: "60px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography fontSize="20px" fontWeight="600">
            Profile
          </Typography>
          <Typography>Personal Profile</Typography>
        </Box>
        <CancelIcon onClick={() => setOpenProfile(false)} />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "200px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Avatar
          sx={{
            backgroundColor: "black",
            color: "#fff",
            width: "115px",
            height: "115px",
          }}
        />
      </Box>
      <Box
        height="80px"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {/* <Typography fontSize="20px" fontWeight="600">
          Jhon Doe
        </Typography>
        <Typography fontSize="11px" fontWeight="400">
          JhonDoe@gmail.com
        </Typography> */}
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          //   justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography color="red"> Change Password</Typography>
        {/* <Typography>
          Edit <EditIcon sx={{ fontSize: "12px" }} />
        </Typography> */}
      </Box>
    </Box>
  );
}

export default Profile;
