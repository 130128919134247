import { Box, Dialog } from '@mui/material';
import React, { useState } from 'react';
import EmojiPicker from 'emoji-picker-react';

function EmojiDialog({ open, closeDialogue, setSelectedEmoji }) {
  //   const [open, setOpen] = useState();

  const handleEmojiClick = (emojiObject) => {
    setSelectedEmoji((prev) => prev.concat(emojiObject.emoji));
  };

  return (
    <Dialog onClose={closeDialogue} open={open}>
      <Box>
        <Box width='100%' bgcolor='black' height='30px' textAlign='center'>
          Smile
        </Box>
        <EmojiPicker onEmojiClick={handleEmojiClick} />
      </Box>
    </Dialog>
  );
}

export default EmojiDialog;
