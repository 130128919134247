import React, { useEffect, useState } from "react";
import Sidebar from "../../components/dashboard/Sidebar";
import { Box, styled } from "@mui/material";
import ContentContailner from "../../components/dashboard/ContendContainer";
// import axios from "axios";
// import toast from "react-hot-toast";
import Footer from "../../components/dashboard/Footer";
import DrawerSidebar from "../../components/common/DrawerSidebar";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const MainBox = styled(Box)({
  display: "flex",
});

function Dashboard() {
  const navigate = useNavigate();
  // const [user, setUser] = useState({});
  // const matches = useMediaQuery("(min-width:990px)");
  //State for handling sidebar
  const [openSidebar, setOpenSidebar] = useState(false);

  //Function to fetch user data
  const getUserData = async () => {};
  //useEffect for fetching the user details
  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleDrawer = (event) => {
    setOpenSidebar(!openSidebar);
  };

  useEffect(() => {
    const isLogin = JSON.parse(localStorage.getItem("login"));

    if (!isLogin) {
      navigate("/login");
    }
  }, []);

  return (
    <>
      <MainBox>
        <DrawerSidebar />
        <Sidebar openSidebar={openSidebar} toggleDrawer={toggleDrawer} />
        <ContentContailner toggleDrawer={toggleDrawer} />
      </MainBox>
      <Footer />
    </>
  );
}

export default Dashboard;
