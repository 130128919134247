import React, { useEffect } from "react";
import ResetPasswordForm from "../../components/Login/ResetPasswordForm";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function ResetPassword() {
   const navigate = useNavigate();
   const { userId } = useSelector((state) => state.user);

   useEffect(() => {
      if (!userId) {
         navigate("/");
      }
   }, []);

   if (!userId) {
      return null;
   }

   return <ResetPasswordForm />;
}

export default ResetPassword;
