import React from "react";
import { sendMessageStatus } from "../../../constant/helperFunctions";
import { Avatar, Box, Button, Typography, styled } from "@mui/material";
import moment from "moment";

const Message = styled(Box)({
   display: "flex",
   flexDirection: "column",
});

function ListMessage({ content }) {
   const { message_input } = content;
   let parcedMessage, type, bodyText, headerText, footerText, actions, buttons;

   if (message_input) {
      parcedMessage = JSON.parse(message_input);
      type = parcedMessage?.interactive?.type;
      bodyText = parcedMessage?.interactive?.body?.text;
      headerText = parcedMessage?.interactive?.header?.text;
      footerText = parcedMessage?.interactive?.footer?.text;
      actions = parcedMessage?.interactive?.action;
      buttons = parcedMessage?.interactive?.action?.button;
   }
   const reply = content?.message?.interactive?.list_reply;


   const time = moment(content.createdAt).format("h:mm a");

   const statusIcon = sendMessageStatus(content?.status);

   return (
      <>
         {content?.flag === "incoming" && (
            <Box
               sx={{
                  height: "auto",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                  maxWidth: content.flag === "incoming" ? "450px" : null,
                  justifyContent: content.flag === "outgoing" ? "end" : "start",
               }}
            >
               {content.flag === "incoming" && (
                  <Box p={1}>
                     <Avatar
                        sx={{
                           width: "30px",
                           height: "30px",
                           backgroundColor: "black",
                           color: "#fff",
                        }}
                     />
                  </Box>
               )}
               <Message>
                  <Box
                     p={1}
                     sx={{
                        width: "auto",
                        height: "auto",
                        backgroundColor:
                           content.flag === "incoming" ? "#F4F4F6" : "#d9fdd3",
                        borderRadius:
                           content.flag === "incoming"
                              ? "0px 15px 15px 15px"
                              : "15px 15px 0px 15px",
                     }}
                  >
                     <Typography sx={{ fontSize: "13px" }}>
                        {reply?.title}
                     </Typography>
                     <Typography sx={{ fontSize: "12px", color: "grey" }}>
                        {reply?.description}
                     </Typography>
                     <Box
                        sx={{
                           display: "flex",
                           justifyContent: "end",
                           alignItems: "center",
                        }}
                     >
                        <Typography
                           sx={{
                              fontSize: "11px",
                              fontWeight: "400",
                              color: "#978f8f",
                              textAlign:
                                 content.flag === "incoming" ? "start" : "end",
                           }}
                        >
                           {time}
                        </Typography>
                        {content.flag === "outgoing" && statusIcon}
                     </Box>
                  </Box>
               </Message>
               {content.flag === "outgoing" && (
                  <Box p={1}>
                     <Avatar
                        sx={{
                           width: "30px",
                           height: "30px",
                           backgroundColor: "black",
                           color: "#fff",
                        }}
                     />
                  </Box>
               )}
            </Box>
         )}
         {content?.flag === "outgoing" && (
            <Box
               mt={1}
               sx={{
                  height: "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
               }}
            >
               <Message>
                  <Box
                     p={1}
                     sx={{
                        width: "auto",
                        height: "auto",
                        backgroundColor: "#d9fdd3",
                        maxWidth: "300px",
                        //   content.flag === "incoming" ? "#F4F4F6" : "#2A8BC7",
                        borderRadius: "5px 5px 5px 5px",
                        //   content.flag === "incoming"
                        //     ? "0px 15px 15px 15px"
                        //     : "15px 15px 0px 15px",
                     }}
                  >
                     <Typography
                        variant="body1"
                        sx={{
                           fontSize: "14px",
                           color: "black",
                           fontWeight: "600",
                        }}
                     >
                        {headerText}
                     </Typography>
                     <span style={{ whiteSpace: "pre-line", fontSize: "13px" }}>
                        {bodyText}
                     </span>

                     {footerText && (
                        <Typography
                           variant="body1"
                           sx={{ fontSize: "11px", color: "grey" }}
                           color="initial"
                        >
                           {footerText}
                        </Typography>
                     )}
                     <Box
                        sx={{
                           display: "flex",
                           justifyContent: "end",
                           alignItems: "center",
                        }}
                     >
                        <Typography
                           sx={{
                              fontSize: "11px",
                              fontWeight: "400",
                              color: "#978f8f",
                              textAlign:
                                 content?.flag === "incoming" ? "start" : "end",
                           }}
                        >
                           {time}
                        </Typography>
                        {content.flag === "outgoing" && statusIcon}
                     </Box>
                  </Box>
                  {buttons && (
                     <Box
                        borderTop="1px solid whitesmoke"
                        display="flex"
                        justifyContent="space-between"
                     >
                        <Box
                           bgcolor="#d9fdd3"
                           borderRadius="5px"
                           width="100%"
                           textAlign="center"
                           //  color="wh"
                        >
                           <Button
                              variant="text"
                              color="primary"
                              sx={{ textTransform: "capitalize" }}
                           >
                              {buttons}
                           </Button>
                           {actions?.sections?.map((item) => (
                              <React.Fragment key={item?.title}>
                                 <Typography fontSize="14px" color="primary">
                                    {item?.title}
                                 </Typography>
                                 <Box p={1} sx={{ textAlign: "start" }}>
                                    {item?.rows.map((row) => (
                                       <div key={row?.id}>
                                          {/* <li>{row?.id}</li> */}
                                          <p style={{ fontSize: "14px" }}>
                                             {row?.title}
                                          </p>
                                          <p
                                             style={{
                                                color: "grey",
                                                fontSize: "11px",
                                             }}
                                          >
                                             {row?.description}
                                          </p>
                                       </div>
                                    ))}
                                 </Box>
                              </React.Fragment>
                           ))}
                        </Box>
                     </Box>
                  )}
               </Message>
               <Box p={1}>
                  <Avatar
                     sx={{
                        width: "30px",
                        height: "30px",
                        backgroundColor: "black",
                        color: "#fff",
                     }}
                  />
               </Box>
            </Box>
         )}
      </>
   );
}

export default ListMessage;
