import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import API from "../../config/axios";

export const getInsights = createAsyncThunk(
   "insights/getInsights",
   async ({startDate,endDate}) => {
      try {
         const response = await API.get(
            `/reports/analytics?start=${startDate}&end=${endDate}`
         );
         return response.data.data;
      } catch (err) {
         console.log(err);
         throw err;
      }
   }
);

const initialState = {
   loading: null,
   error: null,
   reports: {},
};
const insightSlice = createSlice({
   name: "insights",
   initialState,
   reducers: {},

   extraReducers: (builder) => {
      builder
         .addCase(getInsights.pending, (state) => {
            state.loading = true;
         })
         .addCase(getInsights.fulfilled, (state, action) => {
            state.loading = false;
            state.reports = action.payload;
         })
         .addCase(getInsights.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
         });
   },
});

export default insightSlice.reducer;
export const { setSidebar } = insightSlice.actions;
