import React, { useEffect } from "react";
import Sidebar from "../../components/dashboard/Sidebar";
import MainContainer from "../../components/templates/MainContainer";
import { useNavigate } from "react-router-dom";

function CreateTemplate() {
  const navigate = useNavigate();
  useEffect(() => {
    const isLogin = JSON.parse(localStorage.getItem("login"));

    if (!isLogin) {
      navigate("/");
    }
  }, []);
  return (
    <div style={{ display: "flex" }}>
      <Sidebar />
      <MainContainer />
    </div>
  );
}

export default CreateTemplate;
