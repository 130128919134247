// const languages = {
//   aa: "Afar",
//   ab: "Abkhazian",
//   ace: "Achinese",
//   ach: "Acoli",
//   ada: "Adangme",
//   ady: "Adyghe",
//   ae: "Avestan",
//   aeb: "Tunisian Arabic",
//   af: "Afrikaans",
//   afh: "Afrihili",
//   agq: "Aghem",
//   ain: "Ainu",
//   ak: "Akan",
//   akk: "Akkadian",
//   akz: "Alabama",
//   ale: "Aleut",
//   aln: "Gheg Albanian",
//   alt: "Southern Altai",
//   am: "Amharic",
//   an: "Aragonese",
//   ang: "Old English",
//   anp: "Angika",
//   ar: "Arabic",
//   "ar-001": "Modern Standard Arabic",
//   arc: "Aramaic",
//   arn: "Mapuche",
//   aro: "Araona",
//   arp: "Arapaho",
//   arq: "Algerian Arabic",
//   ars: "Najdi Arabic",
//   "ars-alt-menu": "Arabic, Najdi",
//   arw: "Arawak",
//   ary: "Moroccan Arabic",
//   arz: "Egyptian Arabic",
//   as: "Assamese",
//   asa: "Asu",
//   ase: "American Sign Language",
//   ast: "Asturian",
//   av: "Avaric",
//   avk: "Kotava",
//   awa: "Awadhi",
//   ay: "Aymara",
//   az: "Azerbaijani",
//   "az-alt-short": "Azeri",
//   ba: "Bashkir",
//   bal: "Baluchi",
//   ban: "Balinese",
//   bar: "Bavarian",
//   bas: "Basaa",
//   bax: "Bamun",
//   bbc: "Batak Toba",
//   bbj: "Ghomala",
//   be: "Belarusian",
//   bej: "Beja",
//   bem: "Bemba",
//   bew: "Betawi",
//   bez: "Bena",
//   bfd: "Bafut",
//   bfq: "Badaga",
//   bg: "Bulgarian",
//   bgn: "Western Balochi",
//   bho: "Bhojpuri",
//   bi: "Bislama",
//   bik: "Bikol",
//   bin: "Bini",
//   bjn: "Banjar",
//   bkm: "Kom",
//   bla: "Siksika",
//   bm: "Bambara",
//   bn: "Bangla",
//   bo: "Tibetan",
//   bpy: "Bishnupriya",
//   bqi: "Bakhtiari",
//   br: "Breton",
//   bra: "Braj",
//   brh: "Brahui",
//   brx: "Bodo",
//   bs: "Bosnian",
//   bss: "Akoose",
//   bua: "Buriat",
//   bug: "Buginese",
//   bum: "Bulu",
//   byn: "Blin",
//   byv: "Medumba",
//   ca: "Catalan",
//   cad: "Caddo",
//   car: "Carib",
//   cay: "Cayuga",
//   cch: "Atsam",
//   ccp: "Chakma",
//   ce: "Chechen",
//   ceb: "Cebuano",
//   cgg: "Chiga",
//   ch: "Chamorro",
//   chb: "Chibcha",
//   chg: "Chagatai",
//   chk: "Chuukese",
//   chm: "Mari",
//   chn: "Chinook Jargon",
//   cho: "Choctaw",
//   chp: "Chipewyan",
//   chr: "Cherokee",
//   chy: "Cheyenne",
//   cic: "Chickasaw",
//   ckb: "Central Kurdish",
//   "ckb-alt-menu": "Kurdish, Central",
//   "ckb-alt-variant": "Kurdish, Sorani",
//   co: "Corsican",
//   cop: "Coptic",
//   cps: "Capiznon",
//   cr: "Cree",
//   crh: "Crimean Turkish",
//   crs: "Seselwa Creole French",
//   cs: "Czech",
//   csb: "Kashubian",
//   cu: "Church Slavic",
//   cv: "Chuvash",
//   cy: "Welsh",
//   da: "Danish",
//   dak: "Dakota",
//   dar: "Dargwa",
//   dav: "Taita",
//   de: "German",
//   "de-AT": "Austrian German",
//   "de-CH": "Swiss High German",
//   del: "Delaware",
//   den: "Slave",
//   dgr: "Dogrib",
//   din: "Dinka",
//   dje: "Zarma",
//   doi: "Dogri",
//   dsb: "Lower Sorbian",
//   dtp: "Central Dusun",
//   dua: "Duala",
//   dum: "Middle Dutch",
//   dv: "Divehi",
//   dyo: "Jola-Fonyi",
//   dyu: "Dyula",
//   dz: "Dzongkha",
//   dzg: "Dazaga",
//   ebu: "Embu",
//   ee: "Ewe",
//   efi: "Efik",
//   egl: "Emilian",
//   egy: "Ancient Egyptian",
//   eka: "Ekajuk",
//   el: "Greek",
//   elx: "Elamite",
//   en: "English",
//   "en-AU": "Australian English",
//   "en-CA": "Canadian English",
//   "en-GB": "British English",
//   "en-GB-alt-short": "UK English",
//   "en-US": "American English",
//   "en-US-alt-short": "US English",
//   enm: "Middle English",
//   eo: "Esperanto",
//   es: "Spanish",
//   "es-419": "Latin American Spanish",
//   "es-ES": "European Spanish",
//   "es-MX": "Mexican Spanish",
//   esu: "Central Yupik",
//   et: "Estonian",
//   eu: "Basque",
//   ewo: "Ewondo",
//   ext: "Extremaduran",
//   fa: "Persian",
//   "fa-AF": "Dari",
//   fan: "Fang",
//   fat: "Fanti",
//   ff: "Fulah",
//   fi: "Finnish",
//   fil: "Filipino",
//   fit: "Tornedalen Finnish",
//   fj: "Fijian",
//   fo: "Faroese",
//   fon: "Fon",
//   fr: "French",
//   "fr-CA": "Canadian French",
//   "fr-CH": "Swiss French",
//   frc: "Cajun French",
//   frm: "Middle French",
//   fro: "Old French",
//   frp: "Arpitan",
//   frr: "Northern Frisian",
//   frs: "Eastern Frisian",
//   fur: "Friulian",
//   fy: "Western Frisian",
//   ga: "Irish",
//   gaa: "Ga",
//   gag: "Gagauz",
//   gan: "Gan Chinese",
//   gay: "Gayo",
//   gba: "Gbaya",
//   gbz: "Zoroastrian Dari",
//   gd: "Scottish Gaelic",
//   gez: "Geez",
//   gil: "Gilbertese",
//   gl: "Galician",
//   glk: "Gilaki",
//   gmh: "Middle High German",
//   gn: "Guarani",
//   goh: "Old High German",
//   gom: "Goan Konkani",
//   gon: "Gondi",
//   gor: "Gorontalo",
//   got: "Gothic",
//   grb: "Grebo",
//   grc: "Ancient Greek",
//   gsw: "Swiss German",
//   gu: "Gujarati",
//   guc: "Wayuu",
//   gur: "Frafra",
//   guz: "Gusii",
//   gv: "Manx",
//   gwi: "Gwichʼin",
//   ha: "Hausa",
//   hai: "Haida",
//   hak: "Hakka Chinese",
//   haw: "Hawaiian",
//   he: "Hebrew",
//   hi: "Hindi",
//   hif: "Fiji Hindi",
//   hil: "Hiligaynon",
//   hit: "Hittite",
//   hmn: "Hmong",
//   ho: "Hiri Motu",
//   hr: "Croatian",
//   hsb: "Upper Sorbian",
//   hsn: "Xiang Chinese",
//   ht: "Haitian Creole",
//   hu: "Hungarian",
//   hup: "Hupa",
//   hy: "Armenian",
//   hz: "Herero",
//   ia: "Interlingua",
//   iba: "Iban",
//   ibb: "Ibibio",
//   id: "Indonesian",
//   ie: "Interlingue",
//   ig: "Igbo",
//   ii: "Sichuan Yi",
//   ik: "Inupiaq",
//   ilo: "Iloko",
//   inh: "Ingush",
//   io: "Ido",
//   is: "Icelandic",
//   it: "Italian",
//   iu: "Inuktitut",
//   izh: "Ingrian",
//   ja: "Japanese",
//   jam: "Jamaican Creole English",
//   jbo: "Lojban",
//   jgo: "Ngomba",
//   jmc: "Machame",
//   jpr: "Judeo-Persian",
//   jrb: "Judeo-Arabic",
//   jut: "Jutish",
//   jv: "Javanese",
//   ka: "Georgian",
//   kaa: "Kara-Kalpak",
//   kab: "Kabyle",
//   kac: "Kachin",
//   kaj: "Jju",
//   kam: "Kamba",
//   kaw: "Kawi",
//   kbd: "Kabardian",
//   kbl: "Kanembu",
//   kcg: "Tyap",
//   kde: "Makonde",
//   kea: "Kabuverdianu",
//   ken: "Kenyang",
//   kfo: "Koro",
//   kg: "Kongo",
//   kgp: "Kaingang",
//   kha: "Khasi",
//   kho: "Khotanese",
//   khq: "Koyra Chiini",
//   khw: "Khowar",
//   ki: "Kikuyu",
//   kiu: "Kirmanjki",
//   kj: "Kuanyama",
//   kk: "Kazakh",
//   kkj: "Kako",
//   kl: "Kalaallisut",
//   kln: "Kalenjin",
//   km: "Khmer",
//   kmb: "Kimbundu",
//   kn: "Kannada",
//   ko: "Korean",
//   koi: "Komi-Permyak",
//   kok: "Konkani",
//   kos: "Kosraean",
//   kpe: "Kpelle",
//   kr: "Kanuri",
//   krc: "Karachay-Balkar",
//   kri: "Krio",
//   krj: "Kinaray-a",
//   krl: "Karelian",
//   kru: "Kurukh",
//   ks: "Kashmiri",
//   ksb: "Shambala",
//   ksf: "Bafia",
//   ksh: "Colognian",
//   ku: "Kurdish",
//   kum: "Kumyk",
//   kut: "Kutenai",
//   kv: "Komi",
//   kw: "Cornish",
//   ky: "Kyrgyz",
//   "ky-alt-variant": "Kirghiz",
//   la: "Latin",
//   lad: "Ladino",
//   lag: "Langi",
//   lah: "Lahnda",
//   lam: "Lamba",
//   lb: "Luxembourgish",
//   lez: "Lezghian",
//   lfn: "Lingua Franca Nova",
//   lg: "Ganda",
//   li: "Limburgish",
//   lij: "Ligurian",
//   liv: "Livonian",
//   lkt: "Lakota",
//   lmo: "Lombard",
//   ln: "Lingala",
//   lo: "Lao",
//   lol: "Mongo",
//   lou: "Louisiana Creole",
//   loz: "Lozi",
//   lrc: "Northern Luri",
//   lt: "Lithuanian",
//   ltg: "Latgalian",
//   lu: "Luba-Katanga",
//   lua: "Luba-Lulua",
//   lui: "Luiseno",
//   lun: "Lunda",
//   luo: "Luo",
//   lus: "Mizo",
//   luy: "Luyia",
//   lv: "Latvian",
//   lzh: "Literary Chinese",
//   lzz: "Laz",
//   mad: "Madurese",
//   maf: "Mafa",
//   mag: "Magahi",
//   mai: "Maithili",
//   mak: "Makasar",
//   man: "Mandingo",
//   mas: "Masai",
//   mde: "Maba",
//   mdf: "Moksha",
//   mdr: "Mandar",
//   men: "Mende",
//   mer: "Meru",
//   mfe: "Morisyen",
//   mg: "Malagasy",
//   mga: "Middle Irish",
//   mgh: "Makhuwa-Meetto",
//   mgo: "Metaʼ",
//   mh: "Marshallese",
//   mi: "Maori",
//   mic: "Mi'kmaq",
//   min: "Minangkabau",
//   mk: "Macedonian",
//   ml: "Malayalam",
//   mn: "Mongolian",
//   mnc: "Manchu",
//   mni: "Manipuri",
//   moh: "Mohawk",
//   mos: "Mossi",
//   mr: "Marathi",
//   mrj: "Western Mari",
//   ms: "Malay",
//   mt: "Maltese",
//   mua: "Mundang",
//   mul: "Multiple languages",
//   mus: "Creek",
//   mwl: "Mirandese",
//   mwr: "Marwari",
//   mwv: "Mentawai",
//   my: "Burmese",
//   "my-alt-variant": "Myanmar Language",
//   mye: "Myene",
//   myv: "Erzya",
//   mzn: "Mazanderani",
//   na: "Nauru",
//   nan: "Min Nan Chinese",
//   nap: "Neapolitan",
//   naq: "Nama",
//   nb: "Norwegian Bokmål",
//   nd: "North Ndebele",
//   nds: "Low German",
//   "nds-NL": "West Low German",
//   ne: "Nepali",
//   new: "Newari",
//   ng: "Ndonga",
//   nia: "Nias",
//   niu: "Niuean",
//   njo: "Ao Naga",
//   nl: "Dutch",
//   "nl-BE": "Flemish",
//   nmg: "Kwasio",
//   nn: "Norwegian Nynorsk",
//   nnh: "Ngiemboon",
//   no: "Norwegian",
//   nog: "Nogai",
//   non: "Old Norse",
//   nov: "Novial",
//   nqo: "N’Ko",
//   nr: "South Ndebele",
//   nso: "Northern Sotho",
//   nus: "Nuer",
//   nv: "Navajo",
//   nwc: "Classical Newari",
//   ny: "Nyanja",
//   nym: "Nyamwezi",
//   nyn: "Nyankole",
//   nyo: "Nyoro",
//   nzi: "Nzima",
//   oc: "Occitan",
//   oj: "Ojibwa",
//   om: "Oromo",
//   or: "Odia",
//   os: "Ossetic",
//   osa: "Osage",
//   ota: "Ottoman Turkish",
//   pa: "Punjabi",
//   pag: "Pangasinan",
//   pal: "Pahlavi",
//   pam: "Pampanga",
//   pap: "Papiamento",
//   pau: "Palauan",
//   pcd: "Picard",
//   pcm: "Nigerian Pidgin",
//   pdc: "Pennsylvania German",
//   pdt: "Plautdietsch",
//   peo: "Old Persian",
//   pfl: "Palatine German",
//   phn: "Phoenician",
//   pi: "Pali",
//   pl: "Polish",
//   pms: "Piedmontese",
//   pnt: "Pontic",
//   pon: "Pohnpeian",
//   prg: "Prussian",
//   pro: "Old Provençal",
//   ps: "Pashto",
//   "ps-alt-variant": "Pushto",
//   pt: "Portuguese",
//   "pt-BR": "Brazilian Portuguese",
//   "pt-PT": "European Portuguese",
//   qu: "Quechua",
//   quc: "Kʼicheʼ",
//   qug: "Chimborazo Highland Quichua",
//   raj: "Rajasthani",
//   rap: "Rapanui",
//   rar: "Rarotongan",
//   rgn: "Romagnol",
//   rif: "Riffian",
//   rm: "Romansh",
//   rn: "Rundi",
//   ro: "Romanian",
//   "ro-MD": "Moldavian",
//   rof: "Rombo",
//   rom: "Romany",
//   root: "Root",
//   rtm: "Rotuman",
//   ru: "Russian",
//   rue: "Rusyn",
//   rug: "Roviana",
//   rup: "Aromanian",
//   rw: "Kinyarwanda",
//   rwk: "Rwa",
//   sa: "Sanskrit",
//   sad: "Sandawe",
//   sah: "Sakha",
//   sam: "Samaritan Aramaic",
//   saq: "Samburu",
//   sas: "Sasak",
//   sat: "Santali",
//   saz: "Saurashtra",
//   sba: "Ngambay",
//   sbp: "Sangu",
//   sc: "Sardinian",
//   scn: "Sicilian",
//   sco: "Scots",
//   sd: "Sindhi",
//   sdc: "Sassarese Sardinian",
//   sdh: "Southern Kurdish",
//   se: "Northern Sami",
//   see: "Seneca",
//   seh: "Sena",
//   sei: "Seri",
//   sel: "Selkup",
//   ses: "Koyraboro Senni",
//   sg: "Sango",
//   sga: "Old Irish",
//   sgs: "Samogitian",
//   sh: "Serbo-Croatian",
//   shi: "Tachelhit",
//   shn: "Shan",
//   shu: "Chadian Arabic",
//   si: "Sinhala",
//   sid: "Sidamo",
//   sk: "Slovak",
//   sl: "Slovenian",
//   sli: "Lower Silesian",
//   sly: "Selayar",
//   sm: "Samoan",
//   sma: "Southern Sami",
//   smj: "Lule Sami",
//   smn: "Inari Sami",
//   sms: "Skolt Sami",
//   sn: "Shona",
//   snk: "Soninke",
//   so: "Somali",
//   sog: "Sogdien",
//   sq: "Albanian",
//   sr: "Serbian",
//   "sr-ME": "Montenegrin",
//   srn: "Sranan Tongo",
//   srr: "Serer",
//   ss: "Swati",
//   ssy: "Saho",
//   st: "Southern Sotho",
//   stq: "Saterland Frisian",
//   su: "Sundanese",
//   suk: "Sukuma",
//   sus: "Susu",
//   sux: "Sumerian",
//   sv: "Swedish",
//   sw: "Swahili",
//   "sw-CD": "Congo Swahili",
//   swb: "Comorian",
//   syc: "Classical Syriac",
//   syr: "Syriac",
//   szl: "Silesian",
//   ta: "Tamil",
//   tcy: "Tulu",
//   te: "Telugu",
//   tem: "Timne",
//   teo: "Teso",
//   ter: "Tereno",
//   tet: "Tetum",
//   tg: "Tajik",
//   th: "Thai",
//   ti: "Tigrinya",
//   tig: "Tigre",
//   tiv: "Tiv",
//   tk: "Turkmen",
//   tkl: "Tokelau",
//   tkr: "Tsakhur",
//   tl: "Tagalog",
//   tlh: "Klingon",
//   tli: "Tlingit",
//   tly: "Talysh",
//   tmh: "Tamashek",
//   tn: "Tswana",
//   to: "Tongan",
//   tog: "Nyasa Tonga",
//   tpi: "Tok Pisin",
//   tr: "Turkish",
//   tru: "Turoyo",
//   trv: "Taroko",
//   ts: "Tsonga",
//   tsd: "Tsakonian",
//   tsi: "Tsimshian",
//   tt: "Tatar",
//   ttt: "Muslim Tat",
//   tum: "Tumbuka",
//   tvl: "Tuvalu",
//   tw: "Twi",
//   twq: "Tasawaq",
//   ty: "Tahitian",
//   tyv: "Tuvinian",
//   tzm: "Central Atlas Tamazight",
//   udm: "Udmurt",
//   ug: "Uyghur",
//   "ug-alt-variant": "Uighur",
//   uga: "Ugaritic",
//   uk: "Ukrainian",
//   umb: "Umbundu",
//   und: "Unknown language",
//   ur: "Urdu",
//   uz: "Uzbek",
//   vai: "Vai",
//   ve: "Venda",
//   vec: "Venetian",
//   vep: "Veps",
//   vi: "Vietnamese",
//   vls: "West Flemish",
//   vmf: "Main-Franconian",
//   vo: "Volapük",
//   vot: "Votic",
//   vro: "Võro",
//   vun: "Vunjo",
//   wa: "Walloon",
//   wae: "Walser",
//   wal: "Wolaytta",
//   war: "Waray",
//   was: "Washo",
//   wbp: "Warlpiri",
//   wo: "Wolof",
//   wuu: "Wu Chinese",
//   xal: "Kalmyk",
//   xh: "Xhosa",
//   xmf: "Mingrelian",
//   xog: "Soga",
//   yao: "Yao",
//   yap: "Yapese",
//   yav: "Yangben",
//   ybb: "Yemba",
//   yi: "Yiddish",
//   yo: "Yoruba",
//   yrl: "Nheengatu",
//   yue: "Cantonese",
//   "yue-alt-menu": "Chinese, Cantonese",
//   za: "Zhuang",
//   zap: "Zapotec",
//   zbl: "Blissymbols",
//   zea: "Zeelandic",
//   zen: "Zenaga",
//   zgh: "Standard Moroccan Tamazight",
//   zh: "Chinese",
//   "zh-alt-long": "Mandarin Chinese",
//   "zh-alt-menu": "Chinese, Mandarin",
//   "zh-Hans": "Simplified Chinese",
//   "zh-Hans-alt-long": "Simplified Mandarin Chinese",
//   "zh-Hant": "Traditional Chinese",
//   "zh-Hant-alt-long": "Traditional Mandarin Chinese",
//   zu: "Zulu",
//   zun: "Zuni",
//   zxx: "No linguistic content",
//   zza: "Zaza",
// };

const languages = {
  ab: {
    name: "Abkhaz",
    nativeName: "аҧсуа",
  },
  aa: {
    name: "Afar",
    nativeName: "Afaraf",
  },
  af: {
    name: "Afrikaans",
    nativeName: "Afrikaans",
  },
  ak: {
    name: "Akan",
    nativeName: "Akan",
  },
  sq: {
    name: "Albanian",
    nativeName: "Shqip",
  },
  am: {
    name: "Amharic",
    nativeName: "አማርኛ",
  },
  ar: {
    name: "Arabic",
    nativeName: "العربية",
  },
  an: {
    name: "Aragonese",
    nativeName: "Aragonés",
  },
  hy: {
    name: "Armenian",
    nativeName: "Հայերեն",
  },
  as: {
    name: "Assamese",
    nativeName: "অসমীয়া",
  },
  av: {
    name: "Avaric",
    nativeName: "авар мацӀ, магӀарул мацӀ",
  },
  ae: {
    name: "Avestan",
    nativeName: "avesta",
  },
  ay: {
    name: "Aymara",
    nativeName: "aymar aru",
  },
  az: {
    name: "Azerbaijani",
    nativeName: "azərbaycan dili",
  },
  bm: {
    name: "Bambara",
    nativeName: "bamanankan",
  },
  ba: {
    name: "Bashkir",
    nativeName: "башҡорт теле",
  },
  eu: {
    name: "Basque",
    nativeName: "euskara, euskera",
  },
  be: {
    name: "Belarusian",
    nativeName: "Беларуская",
  },
  bn: {
    name: "Bengali",
    nativeName: "বাংলা",
  },
  bh: {
    name: "Bihari",
    nativeName: "भोजपुरी",
  },
  bi: {
    name: "Bislama",
    nativeName: "Bislama",
  },
  bs: {
    name: "Bosnian",
    nativeName: "bosanski jezik",
  },
  br: {
    name: "Breton",
    nativeName: "brezhoneg",
  },
  bg: {
    name: "Bulgarian",
    nativeName: "български език",
  },
  my: {
    name: "Burmese",
    nativeName: "ဗမာစာ",
  },
  ca: {
    name: "Catalan; Valencian",
    nativeName: "Català",
  },
  ch: {
    name: "Chamorro",
    nativeName: "Chamoru",
  },
  ce: {
    name: "Chechen",
    nativeName: "нохчийн мотт",
  },
  ny: {
    name: "Chichewa; Chewa; Nyanja",
    nativeName: "chiCheŵa, chinyanja",
  },
  zh: {
    name: "Chinese",
    nativeName: "中文 (Zhōngwén), 汉语, 漢語",
  },
  cv: {
    name: "Chuvash",
    nativeName: "чӑваш чӗлхи",
  },
  kw: {
    name: "Cornish",
    nativeName: "Kernewek",
  },
  co: {
    name: "Corsican",
    nativeName: "corsu, lingua corsa",
  },
  cr: {
    name: "Cree",
    nativeName: "ᓀᐦᐃᔭᐍᐏᐣ",
  },
  hr: {
    name: "Croatian",
    nativeName: "hrvatski",
  },
  cs: {
    name: "Czech",
    nativeName: "česky, čeština",
  },
  da: {
    name: "Danish",
    nativeName: "dansk",
  },
  dv: {
    name: "Divehi; Dhivehi; Maldivian;",
    nativeName: "ދިވެހި",
  },
  nl: {
    name: "Dutch",
    nativeName: "Nederlands, Vlaams",
  },
  en: {
    name: "English",
    nativeName: "English",
  },
  eo: {
    name: "Esperanto",
    nativeName: "Esperanto",
  },
  et: {
    name: "Estonian",
    nativeName: "eesti, eesti keel",
  },
  ee: {
    name: "Ewe",
    nativeName: "Eʋegbe",
  },
  fo: {
    name: "Faroese",
    nativeName: "føroyskt",
  },
  fj: {
    name: "Fijian",
    nativeName: "vosa Vakaviti",
  },
  fi: {
    name: "Finnish",
    nativeName: "suomi, suomen kieli",
  },
  fr: {
    name: "French",
    nativeName: "français, langue française",
  },
  ff: {
    name: "Fula; Fulah; Pulaar; Pular",
    nativeName: "Fulfulde, Pulaar, Pular",
  },
  gl: {
    name: "Galician",
    nativeName: "Galego",
  },
  ka: {
    name: "Georgian",
    nativeName: "ქართული",
  },
  de: {
    name: "German",
    nativeName: "Deutsch",
  },
  el: {
    name: "Greek, Modern",
    nativeName: "Ελληνικά",
  },
  gn: {
    name: "Guaraní",
    nativeName: "Avañeẽ",
  },
  gu: {
    name: "Gujarati",
    nativeName: "ગુજરાતી",
  },
  ht: {
    name: "Haitian; Haitian Creole",
    nativeName: "Kreyòl ayisyen",
  },
  ha: {
    name: "Hausa",
    nativeName: "Hausa, هَوُسَ",
  },
  he: {
    name: "Hebrew (modern)",
    nativeName: "עברית",
  },
  hz: {
    name: "Herero",
    nativeName: "Otjiherero",
  },
  hi: {
    name: "Hindi",
    nativeName: "हिन्दी, हिंदी",
  },
  ho: {
    name: "Hiri Motu",
    nativeName: "Hiri Motu",
  },
  hu: {
    name: "Hungarian",
    nativeName: "Magyar",
  },
  ia: {
    name: "Interlingua",
    nativeName: "Interlingua",
  },
  id: {
    name: "Indonesian",
    nativeName: "Bahasa Indonesia",
  },
  ie: {
    name: "Interlingue",
    nativeName: "Originally called Occidental; then Interlingue after WWII",
  },
  ga: {
    name: "Irish",
    nativeName: "Gaeilge",
  },
  ig: {
    name: "Igbo",
    nativeName: "Asụsụ Igbo",
  },
  ik: {
    name: "Inupiaq",
    nativeName: "Iñupiaq, Iñupiatun",
  },
  io: {
    name: "Ido",
    nativeName: "Ido",
  },
  is: {
    name: "Icelandic",
    nativeName: "Íslenska",
  },
  it: {
    name: "Italian",
    nativeName: "Italiano",
  },
  iu: {
    name: "Inuktitut",
    nativeName: "ᐃᓄᒃᑎᑐᑦ",
  },
  ja: {
    name: "Japanese",
    nativeName: "日本語 (にほんご／にっぽんご)",
  },
  jv: {
    name: "Javanese",
    nativeName: "basa Jawa",
  },
  kl: {
    name: "Kalaallisut, Greenlandic",
    nativeName: "kalaallisut, kalaallit oqaasii",
  },
  kn: {
    name: "Kannada",
    nativeName: "ಕನ್ನಡ",
  },
  kr: {
    name: "Kanuri",
    nativeName: "Kanuri",
  },
  ks: {
    name: "Kashmiri",
    nativeName: "कश्मीरी, كشميري‎",
  },
  kk: {
    name: "Kazakh",
    nativeName: "Қазақ тілі",
  },
  km: {
    name: "Khmer",
    nativeName: "ភាសាខ្មែរ",
  },
  ki: {
    name: "Kikuyu, Gikuyu",
    nativeName: "Gĩkũyũ",
  },
  rw: {
    name: "Kinyarwanda",
    nativeName: "Ikinyarwanda",
  },
  ky: {
    name: "Kirghiz, Kyrgyz",
    nativeName: "кыргыз тили",
  },
  kv: {
    name: "Komi",
    nativeName: "коми кыв",
  },
  kg: {
    name: "Kongo",
    nativeName: "KiKongo",
  },
  ko: {
    name: "Korean",
    nativeName: "한국어 (韓國語), 조선말 (朝鮮語)",
  },
  ku: {
    name: "Kurdish",
    nativeName: "Kurdî, كوردی‎",
  },
  kj: {
    name: "Kwanyama, Kuanyama",
    nativeName: "Kuanyama",
  },
  la: {
    name: "Latin",
    nativeName: "latine, lingua latina",
  },
  lb: {
    name: "Luxembourgish, Letzeburgesch",
    nativeName: "Lëtzebuergesch",
  },
  lg: {
    name: "Luganda",
    nativeName: "Luganda",
  },
  li: {
    name: "Limburgish, Limburgan, Limburger",
    nativeName: "Limburgs",
  },
  ln: {
    name: "Lingala",
    nativeName: "Lingála",
  },
  lo: {
    name: "Lao",
    nativeName: "ພາສາລາວ",
  },
  lt: {
    name: "Lithuanian",
    nativeName: "lietuvių kalba",
  },
  lu: {
    name: "Luba-Katanga",
    nativeName: "",
  },
  lv: {
    name: "Latvian",
    nativeName: "latviešu valoda",
  },
  gv: {
    name: "Manx",
    nativeName: "Gaelg, Gailck",
  },
  mk: {
    name: "Macedonian",
    nativeName: "македонски јазик",
  },
  mg: {
    name: "Malagasy",
    nativeName: "Malagasy fiteny",
  },
  ms: {
    name: "Malay",
    nativeName: "bahasa Melayu, بهاس ملايو‎",
  },
  ml: {
    name: "Malayalam",
    nativeName: "മലയാളം",
  },
  mt: {
    name: "Maltese",
    nativeName: "Malti",
  },
  mi: {
    name: "Māori",
    nativeName: "te reo Māori",
  },
  mr: {
    name: "Marathi (Marāṭhī)",
    nativeName: "मराठी",
  },
  mh: {
    name: "Marshallese",
    nativeName: "Kajin M̧ajeļ",
  },
  mn: {
    name: "Mongolian",
    nativeName: "монгол",
  },
  na: {
    name: "Nauru",
    nativeName: "Ekakairũ Naoero",
  },
  nv: {
    name: "Navajo, Navaho",
    nativeName: "Diné bizaad, Dinékʼehǰí",
  },
  nb: {
    name: "Norwegian Bokmål",
    nativeName: "Norsk bokmål",
  },
  nd: {
    name: "North Ndebele",
    nativeName: "isiNdebele",
  },
  ne: {
    name: "Nepali",
    nativeName: "नेपाली",
  },
  ng: {
    name: "Ndonga",
    nativeName: "Owambo",
  },
  nn: {
    name: "Norwegian Nynorsk",
    nativeName: "Norsk nynorsk",
  },
  no: {
    name: "Norwegian",
    nativeName: "Norsk",
  },
  ii: {
    name: "Nuosu",
    nativeName: "ꆈꌠ꒿ Nuosuhxop",
  },
  nr: {
    name: "South Ndebele",
    nativeName: "isiNdebele",
  },
  oc: {
    name: "Occitan",
    nativeName: "Occitan",
  },
  oj: {
    name: "Ojibwe, Ojibwa",
    nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ",
  },
  cu: {
    name: "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
    nativeName: "ѩзыкъ словѣньскъ",
  },
  om: {
    name: "Oromo",
    nativeName: "Afaan Oromoo",
  },
  or: {
    name: "Oriya",
    nativeName: "ଓଡ଼ିଆ",
  },
  os: {
    name: "Ossetian, Ossetic",
    nativeName: "ирон æвзаг",
  },
  pa: {
    name: "Panjabi, Punjabi",
    nativeName: "ਪੰਜਾਬੀ, پنجابی‎",
  },
  pi: {
    name: "Pāli",
    nativeName: "पाऴि",
  },
  fa: {
    name: "Persian",
    nativeName: "فارسی",
  },
  pl: {
    name: "Polish",
    nativeName: "polski",
  },
  ps: {
    name: "Pashto, Pushto",
    nativeName: "پښتو",
  },
  pt: {
    name: "Portuguese",
    nativeName: "Português",
  },
  qu: {
    name: "Quechua",
    nativeName: "Runa Simi, Kichwa",
  },
  rm: {
    name: "Romansh",
    nativeName: "rumantsch grischun",
  },
  rn: {
    name: "Kirundi",
    nativeName: "kiRundi",
  },
  ro: {
    name: "Romanian, Moldavian, Moldovan",
    nativeName: "română",
  },
  ru: {
    name: "Russian",
    nativeName: "русский язык",
  },
  sa: {
    name: "Sanskrit (Saṁskṛta)",
    nativeName: "संस्कृतम्",
  },
  sc: {
    name: "Sardinian",
    nativeName: "sardu",
  },
  sd: {
    name: "Sindhi",
    nativeName: "सिन्धी, سنڌي، سندھی‎",
  },
  se: {
    name: "Northern Sami",
    nativeName: "Davvisámegiella",
  },
  sm: {
    name: "Samoan",
    nativeName: "gagana faa Samoa",
  },
  sg: {
    name: "Sango",
    nativeName: "yângâ tî sängö",
  },
  sr: {
    name: "Serbian",
    nativeName: "српски језик",
  },
  gd: {
    name: "Scottish Gaelic; Gaelic",
    nativeName: "Gàidhlig",
  },
  sn: {
    name: "Shona",
    nativeName: "chiShona",
  },
  si: {
    name: "Sinhala, Sinhalese",
    nativeName: "සිංහල",
  },
  sk: {
    name: "Slovak",
    nativeName: "slovenčina",
  },
  sl: {
    name: "Slovene",
    nativeName: "slovenščina",
  },
  so: {
    name: "Somali",
    nativeName: "Soomaaliga, af Soomaali",
  },
  st: {
    name: "Southern Sotho",
    nativeName: "Sesotho",
  },
  es: {
    name: "Spanish; Castilian",
    nativeName: "español, castellano",
  },
  su: {
    name: "Sundanese",
    nativeName: "Basa Sunda",
  },
  sw: {
    name: "Swahili",
    nativeName: "Kiswahili",
  },
  ss: {
    name: "Swati",
    nativeName: "SiSwati",
  },
  sv: {
    name: "Swedish",
    nativeName: "svenska",
  },
  ta: {
    name: "Tamil",
    nativeName: "தமிழ்",
  },
  te: {
    name: "Telugu",
    nativeName: "తెలుగు",
  },
  tg: {
    name: "Tajik",
    nativeName: "тоҷикӣ, toğikī, تاجیکی‎",
  },
  th: {
    name: "Thai",
    nativeName: "ไทย",
  },
  ti: {
    name: "Tigrinya",
    nativeName: "ትግርኛ",
  },
  bo: {
    name: "Tibetan Standard, Tibetan, Central",
    nativeName: "བོད་ཡིག",
  },
  tk: {
    name: "Turkmen",
    nativeName: "Türkmen, Түркмен",
  },
  tl: {
    name: "Tagalog",
    nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔",
  },
  tn: {
    name: "Tswana",
    nativeName: "Setswana",
  },
  to: {
    name: "Tonga (Tonga Islands)",
    nativeName: "faka Tonga",
  },
  tr: {
    name: "Turkish",
    nativeName: "Türkçe",
  },
  ts: {
    name: "Tsonga",
    nativeName: "Xitsonga",
  },
  tt: {
    name: "Tatar",
    nativeName: "татарча, tatarça, تاتارچا‎",
  },
  tw: {
    name: "Twi",
    nativeName: "Twi",
  },
  ty: {
    name: "Tahitian",
    nativeName: "Reo Tahiti",
  },
  ug: {
    name: "Uighur, Uyghur",
    nativeName: "Uyƣurqə, ئۇيغۇرچە‎",
  },
  uk: {
    name: "Ukrainian",
    nativeName: "українська",
  },
  ur: {
    name: "Urdu",
    nativeName: "اردو",
  },
  uz: {
    name: "Uzbek",
    nativeName: "zbek, Ўзбек, أۇزبېك‎",
  },
  ve: {
    name: "Venda",
    nativeName: "Tshivenḓa",
  },
  vi: {
    name: "Vietnamese",
    nativeName: "Tiếng Việt",
  },
  vo: {
    name: "Volapük",
    nativeName: "Volapük",
  },
  wa: {
    name: "Walloon",
    nativeName: "Walon",
  },
  cy: {
    name: "Welsh",
    nativeName: "Cymraeg",
  },
  wo: {
    name: "Wolof",
    nativeName: "Wollof",
  },
  fy: {
    name: "Western Frisian",
    nativeName: "Frysk",
  },
  xh: {
    name: "Xhosa",
    nativeName: "isiXhosa",
  },
  yi: {
    name: "Yiddish",
    nativeName: "ייִדיש",
  },
  yo: {
    name: "Yoruba",
    nativeName: "Yorùbá",
  },
  za: {
    name: "Zhuang, Chuang",
    nativeName: "Saɯ cueŋƅ, Saw cuengh",
  },
};

export default languages;
