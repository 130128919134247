import React, { useEffect } from "react";
import Sidebar from "../../components/dashboard/Sidebar";
import Templates from "../../components/templates/Templates";
import { useNavigate } from "react-router-dom";

function TemplatesList() {
  const navigate = useNavigate();
  useEffect(() => {
    const isLogin = JSON.parse(localStorage.getItem("login"));

    if (!isLogin) {
      navigate("/login");
    }
  }, []);
  return (
    <div style={{ display: "flex" }}>
      <Sidebar />
      <Templates />
    </div>
  );
}

export default TemplatesList;
