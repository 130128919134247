import { Avatar, Box, Typography, styled } from "@mui/material";
import React from "react";
import { sendMessageStatus } from "../../../constant/helperFunctions";

import moment from "moment/moment";
const Message = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

function ImageMessage({ content }) {
  const url = process.env.REACT_APP_IMAGE_URL;
  const time = moment(content.createdAt).format("h:mm a");
  const statusIcon = sendMessageStatus(content?.status);
  const path= content?.message?.image?.path

  return (
    <Box
      //   key={mess._id}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: content.flag === "incoming" ? "start" : "end",
      }}
    >
      {content.flag === "incoming" && (
        <Box p={1}>
          <Avatar
            sx={{
              width: "30px",
              height: "30px",
              backgroundColor: "black",
              color: "#fff",
            }}
          />
        </Box>
      )}
      <Message>
        {/* <Typography sx={{ fontSize: "11px", fontWeight: "600" }}>
          John Doe
        </Typography> */}
        <Box
          p={1}
          mt={1}
          sx={{
            width: "250px",
            backgroundColor: content.flag === "incoming" ? "#F4F4F6" : "#d9fdd3",
            borderRadius: content.flag === "incoming" ? "0px 15px 15px 15px" : "15px 15px 0px 15px",
          }}
        >
          {content?.message?.type === "video" ? (
            <video style={{ objectFit: "contain", height: "100%", width: "250px" }} controls>
              <source src={`${url}/${content?.message?.image?.path}`} type="video/mp4" />
            </video>
          ) : (
            <img src={`${url}/${content?.message?.image?.path}`} alt="whatsappimage" style={{ width: "250px" }} />
          )}
          <Typography sx={{ fontSize: "13px" }}>{content.message?.video?.caption||content.message?.image?.caption}</Typography>
          <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: "11px",
                fontWeight: "400",
                color: "#978f8f",
                textAlign: content.flag === "incoming" ? "start" : "end",
              }}
            >
              {time}
            </Typography>
            {content.flag === "outgoing"&&statusIcon}
          </Box>
        </Box>
      </Message>
      {content.flag === "outgoing" && (
        <Box p={1}>
          <Avatar
            sx={{
              width: "30px",
              height: "30px",
              backgroundColor: "black",
              color: "#fff",
            }}
          />
        </Box>
      )}
    </Box>
  );
}

export default ImageMessage;
