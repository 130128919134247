import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import WarningIcon from "@mui/icons-material/Warning";

const style = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: 400,
   bgcolor: "background.paper",
   border: "2px solid #000",
   boxShadow: 24,
   p: 4,
};

function WarningModal({ open, setOpen, modelName, modelContent,fun }) {
   const handleClose = () => setOpen(false);

   return (
      <div>
         <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
               backdrop: {
                  timeout: 500,
               },
            }}
         >
            <Fade in={open}>
               <Box sx={style}>
                  <Box width="100%">
                     <WarningIcon />
                  </Box>
                  <Typography
                     id="transition-modal-title"
                     variant="h6"
                     component="h2"
                  >
                     {modelName} ?
                  </Typography>
                  <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                     {modelContent}
                  </Typography>
                  <Box mt={2} display="flex" justifyContent="center" gap={2}>
                     <Button variant="outlined" sx={{color:"black",borderColor:"black",textTransform:"capitalize"}} onClick={handleClose}>No, Cancel</Button>
                     <Button variant="contained" sx={{backgroundColor:"#a51111",color:"#fff",textTransform:"capitalize"}} onClick={()=>fun()}>Yes, I'm sure</Button>
                  </Box>
               </Box>
            </Fade>
         </Modal>
      </div>
   );
}

export default WarningModal;
