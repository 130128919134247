import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const MainBox = styled(Box)({
  borderRadius: "15px",
  backgroundColor: "#fff",
  //   padding: '25px',
  maxHeight: "430px",
  height: "430px",
  overflowY: "scroll",
});
const OuterBox = styled(Box)({
  width: "100%",
  height: "auto",
  borderTop: "1px solid grey",
});
const CustomTableCell = styled(TableCell)({
  color: "white",
  fontSize: "14px",
  fontWeight: "400",
  borderRight: "1px solid white",
  textAlign: "center",
});

function ReportTable({ reports }) {
  const navigate = useNavigate();
  const [selectedTemplate, setSelectedTemplate] = useState();
  //   moment(content.createdAt).format("h:mm a");
  const getDate = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };
  const getTime = (date) => moment(date).format("h:mm a");

  const handleClick = (data) => {
    setSelectedTemplate(data);
    navigate(`/bulk-send-view/${data}`);
  };
  return (
    <OuterBox mt={8} ml={5} mr={4}>
      <Toaster />
      <Typography fontSize="20px" fontWeight="600" mt={2}>
        Bulk Send
      </Typography>

      <MainBox mt={2}>
        <TableContainer>
          <Table>
            <TableHead
              sx={{
                background: "#8EB7FF",
                color: "#fff",
                height: "56px",
              }}
            >
              <TableRow
                sx={{
                  height: "20px",
                  "& .MuiTableRow-root": {
                    height: "20px",
                  },
                }}
              >
                <CustomTableCell>Date</CustomTableCell>
                <CustomTableCell>Template Name</CustomTableCell>
                <CustomTableCell>Time</CustomTableCell>
                <CustomTableCell>Contact Out</CustomTableCell>
                <CustomTableCell>Send Out</CustomTableCell>
                <CustomTableCell>Delivered</CustomTableCell>
                <CustomTableCell>Read</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reports?.map((item) => (
                <TableRow
                  key={item?._id}
                  onClick={() => handleClick(item?._id)}
                  sx={{
                    "& .MuiTableCell-root": {
                      border: "1px solid #8080803d",
                      textAlign: "center",
                    },
                    cursor: "pointer",
                    ":hover": {
                      backgroundColor: "whitesmoke",
                    },
                  }}
                >
                  <TableCell>{getDate(item?.data?.createdAt)}</TableCell>
                  <TableCell>{item?.data?.template_name}</TableCell>
                  <TableCell>{getTime(item?.data?.createdAt)}</TableCell>
                  <TableCell>{item?.data?.contacts?.length}</TableCell>
                  <TableCell align="center">{`${item?.send}/${item?.data?.totalContacts}`}</TableCell>
                  <TableCell width="50px">{`${item?.delivered}/${item?.data?.totalContacts}`}</TableCell>
                  <TableCell align="center">{`${item?.read}/${item?.data?.totalContacts}`}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </MainBox>
    </OuterBox>
  );
}

export default ReportTable;
