import {
   Alert,
   Avatar,
   Box,
   Button,
   Menu,
   MenuItem,
   Paper,
   StyledEngineProvider,
   TextField,
   Typography,
   styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import toast from "react-hot-toast";
import { saveSelectedChat } from "../../../store/slices/messageSlice";
import { useDispatch, useSelector } from "react-redux";
import { getAllChats } from "../../../store/slices/chatSlice";
import API from "../../../config/axios";
import AssignLabelModal from "./AssignLabelModal";

const HeaderBox = styled(Box)({
   borderBottom: "1px solid #80808026",
   padding: "15px",
   display: "flex",
   justifyContent: "space-between",
});
const LableItem = styled(Box)({
   width: 0,
   height: 0,
   borderTop: "13px solid transparent",
   borderRight: "26px solid #426a8dcf",
   borderBottom: "13px solid transparent",
});

const CustomTextField = styled(TextField)({
   focused: {
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
         borderColor: "green", // Customize the focus color here
      },
   },
   backgroundColor: "#fff",
   "& .MuiInputBase-root": {
      backgroundColor: "#fff",
      ":hover": { backgroundColor: "#fff" },
   },
});

function ChatHeader({ selectedChat, lastMessageTime }) {
   const [name, setName] = useState("");
   const [anchorEl, setAnchorEl] = useState(null);
   const [openAddLabel, setopenAddLabel] = useState(false);
   const [labelName, setLabelName] = useState("");
   const { labels } = useSelector((state) => state.labels);

   const dispatch = useDispatch();
   const open = Boolean(anchorEl);
   const handleClick = (event) => setAnchorEl(event.currentTarget);

   const handleClose = () => {
      setAnchorEl(null);
   };

   useEffect(() => {
      setName(selectedChat?.name);
      const { labelCategory } = selectedChat?.label || {};
      if (labelCategory) {
         setLabelName(labelCategory);
      } else setLabelName("");
   }, [selectedChat]);

   const [anchorEl2, setAnchorEl2] = React.useState(null);
   const open2 = Boolean(anchorEl);
   const handleClickProfile = (event) => {
      handleClose();
      setAnchorEl2(event.currentTarget);
   };
   const handleClose2 = () => {
      setAnchorEl2(null);
   };

   const handleNameChange = (e) => setName(e.target.value);
   const handleUpdateName = async () => {
      try {
         const response = await API.put(`/contacts/update-contact`, {
            chatId: selectedChat?._id,
            name: name,
         });

         if (response && response?.data?.contact) {
            toast.success("Updated successfully");
            dispatch(saveSelectedChat(response?.data?.contact));
            dispatch(getAllChats());
         }
      } catch (err) {
         console.log(err);
      }
   };

   return (
      <HeaderBox>
         <Box display="flex" justifyContent="space-between">
            <Avatar
               sx={{
                  backgroundColor: "black",
                  color: "white",
                  width: "45px",
                  height: "45px",
               }}
            />
            <Box ml={3}>
               <Typography sx={{ fontWeight: "600", fontSize: "16px" }}>
                  {selectedChat?.name}
               </Typography>
               <Typography
                  fontSize={selectedChat?.name ? "13px" : "16px"}
                  fontWeight="500"
               >
                  {selectedChat?.phone_number}
               </Typography>
               {selectedChat?.chat?.last_message_at && (
                  <Typography fontSize="11px" fontWeight="400px" color="grey">
                     Last Seen {lastMessageTime}
                  </Typography>
               )}
               {/* <Button onClick={() => handleLoadMore()}>Load More</Button> */}
            </Box>
         </Box>
         <MoreVertIcon
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
         />
         <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
               "aria-labelledby": "basic-button",
            }}
         >
            <Box p={2} width={300}>
               <Box p={2} display="flex" justifyContent="space-between">
                  <Typography fontWeight={500}>Contact Info</Typography>

                  {/* <CancelIcon onClick={() => setOpenProfile(false)} /> */}
               </Box>

               <Box pl={2} pr={2} pb={2}>
                  <Typography>Name</Typography>
                  <CustomTextField
                     id="filled-start-adornment"
                     fullWidth
                     value={name}
                     onChange={handleNameChange}
                     sx={{
                        backgroundColor: "#fff",
                        "& .MuiInputBase-root": {
                           backgroundColor: "#fff",
                           ":hover": { backgroundColor: "#fff" },
                        },
                     }}
                     variant="standard"
                  />
                  <Typography mt={2}>Phone Number</Typography>
                  <CustomTextField
                     id="filled-start-adornment"
                     fullWidth
                     disabled
                     value={selectedChat?.phone_number}
                     sx={{
                        backgroundColor: "#fff",
                        "& .MuiInputBase-root": {
                           backgroundColor: "#fff",
                           ":hover": { backgroundColor: "#fff" },
                        },
                     }}
                     variant="standard"
                  />
                  <Typography mt={2}>Category</Typography>
                  <Box display="flex">
                     {labelName && <LableItem></LableItem>}
                     {labelName && (
                        <Box height={26} width={200} bgcolor="#426a8dcf">
                           <span
                              style={{
                                 color: "#fff",
                                 fontSize: "13px",
                                 marginLeft: "10px",
                              }}
                           >
                              {labelName}
                           </span>
                        </Box>
                     )}
                     <EditIcon
                        sx={{ marginLeft: "10px", cursor: "pointer" }}
                        onClick={() => setopenAddLabel(true)}
                     />
                  </Box>
               </Box>
               {selectedChat?.name !== name && (
                  <Button sx={{ marginTop: "10px" }} onClick={handleUpdateName}>
                     Save
                  </Button>
               )}
            </Box>
         </Menu>
         <AssignLabelModal
            openAddLabel={openAddLabel}
            setopenAddLabel={setopenAddLabel}
            selectedChat={selectedChat}
         />
      </HeaderBox>
   );
}

export default ChatHeader;
