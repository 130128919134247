import React, {  useEffect, useState } from "react";
import { Box, CircularProgress, styled, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import sendMessageIcon from "../../../assets/images/send-message.png";
import noPreviewImage from "../../../assets/images/nopreview.jpeg";

const ContentBox = styled(Box)({
  height: "370px",
  width: "100%",
  backgroundColor: "#d1d7db",
  borderRadius: "15px",
  display: "flex",
  flexDirection: "column",
});

function FileDisplayBox({ file, handleRemoveFile, setTextMessage, textMessage, handleSendMessage, setMessageType, loading }) {
  const [previewImage, setPreviewImage] = useState("");
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [mediaType, setMediaType] = useState("");

  useEffect(() => {
    if (file) {
      if (file.type === "video/mp4") {
        setMediaType("video");
      } else if (file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "image/png") {
        setMediaType("image");
      } else {
        setMediaType("document");
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
    }
  }, [file]);

  return (
    <ContentBox>
      <Box display="flex" p={1} height="25px" bgcolor="#bdbdbd">
        <Typography fontSize="18px" fontWeight="600" sx={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon="fa-solid fa-xmark" onClick={handleRemoveFile} />
        </Typography>
        <Typography ml={3}>{file?.name}</Typography>
      </Box>
      <Box height="100%" padding={1} display="flex" justifyContent="center">
        {mediaType === "document" && <img src={noPreviewImage} alt="uploaded" style={{ height: "250px" }} />}
        {mediaType === "image" && <img src={previewImage} alt="uploaded" style={{ height: "250px" }} />}
        {mediaType === "video" && (
          <video width="320" height="240" controls src={previewImage}>
            Your browser does not support the video tag.
          </video>
        )}
      </Box>
      <Box sx={{ width: "100%", flex: "end" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            border: "1px solid #80808026",
            backgroundColor: "#f0f2f5",
          }}
        >
          <Box
            p={2}
            sx={{
              backgroundColor: "#99999926",
              color: "#999",
            }}
          >
            <FontAwesomeIcon icon="fa-regular fa-face-smile" />
          </Box>

          <input
            placeholder="Type a Message"
            value={textMessage}
            onChange={(e) => setTextMessage(e.target.value)}
            style={{
              width: "100%",
              height: "40px",
              outline: "none",
              border: "none",
              paddingLeft: "25px",
              backgroundColor: "#f0f2f5",
            }}
          />

          <Box
            onClick={() => handleSendMessage()}
            p={2}
            sx={{
              backgroundColor: "#00A884",
              color: "#999",
              borderRadius: "0px 15px 15px 0px",
              height: "20px",

              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <CircularProgress
                sx={{
                  "& .MuiCircularProgress-root": {
                    width: "20px",
                    height: "20px",
                    color: "#fff",
                  },
                }}
              />
            ) : (
              <img src={sendMessageIcon} alt="send" />
            )}
          </Box>
        </Box>
      </Box>
    </ContentBox>
  );
}

export default FileDisplayBox;
