import { Avatar, Box, Divider, Stack, Typography, styled } from "@mui/material";
import moment from "moment";
import React from "react";
import { Document, Page } from "react-pdf";
import documentIcon from "../../../assets/images/word.jpg";
import pdfIcon from "../../../assets/images/pdf.png";
import excelfIcon from "../../../assets/images/excel-logo.png";
import { sendMessageStatus } from "../../../constant/helperFunctions";

const Message = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

function DocumentMessage({ content }) {
  const url = process.env.REACT_APP_IMAGE_URL;
  const time = moment(content.createdAt).format("h:mm a");
  const extension = content?.message?.image?.path?.split(".").pop() || content?.message?.document?.path?.split(".").pop();
  const statusIcon = sendMessageStatus(content?.status);
  const fileName= content?.message?.image?.filename ||content?.message?.document?.filename
  const documentUrl = `${url}/${content?.message?.image?.path || content?.message?.document?.path}`;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: content.flag === "incoming" ? "start" : "end",
        }}
      >
        {content.flag === "incoming" && (
          <Box p={1}>
            <Avatar
              sx={{
                width: "30px",
                height: "30px",
                backgroundColor: "black",
                color: "#fff",
              }}
            />
          </Box>
        )}
        <Message>
          <Box
            p={1}
            mt={1}
            sx={{
              width: "fit-content",
              height: "100px",
              backgroundColor: content.flag === "incoming" ? "#F4F4F6" : "#d9fdd3",
              borderRadius: content.flag === "incoming" ? "0px 15px 15px 15px" : "15px 15px 0px 15px",
            }}
          >
            <Box sx={{ width: "200px", bgcolor: "#f0f2f5", p: 1, display: "flex" }}>
              <a href={documentUrl} target="_blank" rel="noopener noreferrer">
                <div style={{ height: "40px", width: "30px", display: "flex" }}>
                  {extension === "pdf" && <img src={pdfIcon} alt="document" width="100%" height="30px" />}
                  {extension === "xlsx" && <img src={excelfIcon} alt="document" width="100%" height="30px" />}
                </div>
                <div>
                  <p style={{ color: "grey", fontSize: "11px" }}>{fileName}</p>
                </div>
              </a>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
              <Typography
                sx={{
                  fontSize: "11px",
                  fontWeight: "400",
                  color: "#978f8f",
                  textAlign: content.flag === "incoming" ? "start" : "end",
                }}
              >
                {time}
              </Typography>
              {content.flag === "outgoing" && statusIcon}
            </Box>
          </Box>
        </Message>
        {content.flag === "outgoing" && (
          <Box p={1}>
            <Avatar
              sx={{
                width: "30px",
                height: "30px",
                backgroundColor: "black",
                color: "#fff",
              }}
            />
          </Box>
        )}
      </Box>
    </>
  );
}

export default DocumentMessage;
