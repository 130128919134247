import React from "react";
import Sidebar from "../../../components/dashboard/Sidebar";
import BulkSendSingleView from "../../../components/reports/bulkSendReport/BulkSendSingleView";

function BulkSendDetail() {
  return (
    <div style={{ display: "flex" }}>
      <Sidebar />
      <BulkSendSingleView />
    </div>
  );
}

export default BulkSendDetail;
