import {
  Box,
  Grid,
  ListItemIcon,
  SvgIcon,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TelegramIcon from "@mui/icons-material/Telegram";
import { useNavigate } from "react-router-dom";

const CardBox = styled(Grid)({
  width: "100%",
  // marginLeft: "-24px",
  display: "flex",
  flexWrap: "wrap",
});

const CustomCard = styled(Box)({
  minHeight: "170px",
  borderRadius: "15px",
  marginBottom: "25px",
  // background: "linear-gradient(to right, #faf9f8a1, #f2eded14)",
  background:
    "linear-gradient(118deg, rgba(255, 255, 255, 0.80) 15%, rgba(255, 255, 255, 0.50) 70%)",
  display: "flex",
  flexDirection: "column",
  padding: "5px",
  "&:hover": {
    boxShadow: "0 16px 22px rgba(0,50,125,.15)",
  },
});

const staticContend = [{}];

function CardContainer() {
  const navigate = useNavigate();
  return (
    <Box mt={1}>
      <Box display="flex" justifyContent="start">
        <Typography
          mt={2}
          sx={{ color: "black", fontSize: "20px", fontWeight: "600" }}
        >
          Dashboard
        </Typography>
      </Box>
      <Box sx={{ borderBottom: "1px solid #ffffff30", pb: "45px" }}>
        <CardBox container ml="2px" mt={1} spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CustomCard onClick={()=>navigate("/chat")}>
              <Box
                p={2}
                sx={{
                  height: "50%",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  style={{ fontSize: "3rem", color: "rgb(13 110 253)" }}
                  icon="fa-brands fa-whatsapp"
                />
              </Box>
              <Box height="50%" pl={2} pr={2} pb={2}>
                <Typography sx={{ fontWeight: "700", fontSize: "18px" }}>
                  WhatsApp
                </Typography>
                <Typography sx={{ fontWeight: "400", fontSize: "15px" }}>
                  send and receive Whatsapp message within your inbox
                </Typography>
              </Box>
            </CustomCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CustomCard>
              <Box
                p={2}
                sx={{
                  height: "50%",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  style={{ fontSize: "3rem", color: "rgb(13 110 253)" }}
                  icon="fa-brands fa-facebook-messenger"
                />
              </Box>
              <Box height="50%" pl={2} pr={2} pb={2}>
                <Typography sx={{ fontWeight: "700", fontSize: "18px" }}>
                  Messenger
                </Typography>
                <Typography sx={{ fontWeight: "400", fontSize: "15px" }}>
                  Messenger chats connection
                </Typography>
              </Box>
            </CustomCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CustomCard>
              <Box
                p={2}
                sx={{
                  height: "50%",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <TelegramIcon
                  sx={{ fontSize: "3rem", color: "rgb(13 110 253)" }}
                />
              </Box>
              <Box height="50%" pl={2} pr={2} pb={2}>
                <Typography sx={{ fontWeight: "700", fontSize: "18px" }}>
                  Insta Direct Message
                </Typography>
                <Typography sx={{ fontWeight: "400", fontSize: "15px" }}>
                  Send and Receive Instagram DMs within your inbox
                </Typography>
              </Box>
            </CustomCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CustomCard>
              <Box
                p={2}
                sx={{
                  height: "50%",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <ListItemIcon
                  sx={{
                    color: "#fff",
                    "& .MuiSvgIcon-root": {
                      fontSize: "3rem",
                    },
                  }}
                >
                  <SvgIcon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="100"
                      height="100"
                      viewBox="0,0,256,256"
                    >
                      <g
                        fill="#228be6"
                        fillRule="nonzero"
                        stroke="none"
                        strokeWidth="1"
                        strokeLinecap="butt"
                        stroke-linejoin="miter"
                        stroke-miterlimit="10"
                        stroke-dashoffset="0"
                        // font-family="none"
                        // font-weight="none"
                        // font-size="none"
                        textAnchor="none"
                      >
                        <g transform="scale(5.12,5.12)">
                          <path d="M12,23.403v-0.013v-13.001l-0.12,-0.089h-0.01l-2.73,-2.02c-1.67,-1.24 -4.05,-1.18 -5.53,0.28c-0.99,0.98 -1.61,2.34 -1.61,3.85v3.602zM38,23.39v0.013l10,-7.391v-3.602c0,-1.49 -0.6,-2.85 -1.58,-3.83c-1.46,-1.457 -3.765,-1.628 -5.424,-0.403l-2.876,2.123l-0.12,0.089zM14,24.868l10.406,7.692c0.353,0.261 0.836,0.261 1.189,0l10.405,-7.692v-13.001l-11,8.133l-11,-8.133zM38,25.889v15.111c0,0.552 0.448,1 1,1h6.5c1.381,0 2.5,-1.119 2.5,-2.5v-21.003zM12,25.889l-10,-7.392v21.003c0,1.381 1.119,2.5 2.5,2.5h6.5c0.552,0 1,-0.448 1,-1z"></path>
                        </g>
                      </g>
                    </svg>
                  </SvgIcon>
                </ListItemIcon>
              </Box>
              <Box height="50%" pl={2} pr={2} pb={2}>
                <Typography sx={{ fontWeight: "700", fontSize: "18px" }}>
                  Email
                </Typography>
                <Typography sx={{ fontWeight: "400", fontSize: "15px" }}>
                  Send and receive Email Message within your inbox
                </Typography>
              </Box>
            </CustomCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CustomCard>
              <Box
                p={2}
                sx={{
                  height: "50%",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  style={{ fontSize: "3rem", color: "rgb(13 110 253)" }}
                  icon="fa-brands fa-snapchat"
                />
              </Box>
              <Box height="50%" pl={2} pr={2} pb={2}>
                <Typography sx={{ fontWeight: "700", fontSize: "18px" }}>
                  Snapchat
                </Typography>
                <Typography sx={{ fontWeight: "400", fontSize: "15px" }}>
                  Snapchat connection
                </Typography>
              </Box>
            </CustomCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CustomCard>
              <Box
                p={2}
                sx={{
                  height: "50%",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <ListItemIcon
                  sx={{
                    color: "black",
                    "& .MuiSvgIcon-root": {
                      fontSize: "3rem",
                    },
                  }}
                >
                  <SvgIcon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="100"
                      height="100"
                      viewBox="0,0,256,256"
                    >
                      <g
                        fill="#228be6"
                        fillRule="nonzero"
                        stroke="none"
                        strokeWidth="1"
                        strokeLinecap="butt"
                        stroke-linejoin="miter"
                        strokeMiterlimit="10"
                        stroke-dashoffset="0"
                        // font-family="none"
                        // font-weight="none"
                        // font-size="none"
                        textAnchor="none"
                      >
                        <g transform="scale(5.12,5.12)">
                          <path d="M9.2832,4c-1.79427,0 -3.37799,1.2052 -3.85547,2.93555l-3.42773,12.42969v0.13477c0,1.83366 0.77034,3.49175 2,4.67578v18.82422c0,1.64497 1.35503,3 3,3h36c1.64497,0 3,-1.35503 3,-3v-18.82422c1.22966,-1.18403 2,-2.84213 2,-4.67578v-0.13477l-3.42969,-12.42969c-0.47735,-1.72989 -2.06053,-2.93555 -3.85547,-2.93555zM9.2832,6h5.57031l-1.65625,12h-8.74609l2.9043,-10.53125c0.24052,-0.87165 1.024,-1.46875 1.92773,-1.46875zM16.87109,6h7.12891v12h-8.7832zM26,6h7.12891l1.6543,12h-8.7832zM35.14648,6h5.56836c0.90506,0 1.68708,0.59664 1.92773,1.46875l2.90625,10.53125h-8.74609zM4.06445,20h8.88477c-0.2495,2.25621 -2.12302,4 -4.44922,4c-2.32472,0 -4.18564,-1.74538 -4.43555,-4zM15.09961,20h8.80078c-0.2574,2.24762 -2.08025,4 -4.40039,4c-2.32014,0 -4.14299,-1.75238 -4.40039,-4zM26.09961,20h8.80078c-0.2574,2.24762 -2.08025,4 -4.40039,4c-2.32014,0 -4.14299,-1.75238 -4.40039,-4zM37.05078,20h8.88477c-0.2499,2.25462 -2.11083,4 -4.43555,4c-2.3262,0 -4.19971,-1.74379 -4.44922,-4zM25,22.74805c1.13554,1.90643 3.12912,3.25195 5.5,3.25195c2.32974,0 4.34262,-1.25166 5.4668,-3.11133c1.14591,1.86126 3.19638,3.11133 5.5332,3.11133c0.88501,0 1.72959,-0.17877 2.5,-0.50195v17.50195c0,0.56503 -0.43497,1 -1,1h-36c-0.56503,0 -1,-0.43497 -1,-1v-17.50195c0.77041,0.32318 1.61499,0.50195 2.5,0.50195c2.33682,0 4.38729,-1.25007 5.5332,-3.11133c1.12418,1.85966 3.13706,3.11133 5.4668,3.11133c2.37088,0 4.36446,-1.34552 5.5,-3.25195zM35.5,29c-3.584,0 -6.5,2.916 -6.5,6.5c0,3.584 2.916,6.5 6.5,6.5c3.584,0 6.5,-2.916 6.5,-6.5c0,-0.169 -0.01339,-0.334 -0.02539,-0.5h-5.97461v2h3.72461c-0.621,1.742 -2.27161,3 -4.22461,3c-2.481,0 -4.5,-2.019 -4.5,-4.5c0,-2.481 2.019,-4.5 4.5,-4.5c1.496,0 2.81381,0.74119 3.63281,1.86719l1.43164,-1.43164c-1.193,-1.482 -3.01845,-2.43555 -5.06445,-2.43555z"></path>
                        </g>
                      </g>
                    </svg>
                  </SvgIcon>
                </ListItemIcon>
              </Box>
              <Box height="50%" pl={2} pr={2} pb={2}>
                <Typography sx={{ fontWeight: "700", fontSize: "18px" }}>
                  My Business
                </Typography>
                <Typography sx={{ fontWeight: "400", fontSize: "15px" }}>
                  Business Profile connection
                </Typography>
              </Box>
            </CustomCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CustomCard>
              <Box
                p={2}
                className="dashboard_box"
                sx={{
                  height: "50%",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <div className="cipherAnts_logo"></div>
              </Box>
              <Box height="50%" pl={2} pr={2} pb={2}>
                <Typography sx={{ fontWeight: "700", fontSize: "18px" }}>
                  CipherAnts
                </Typography>
                <Typography sx={{ fontWeight: "400", fontSize: "15px" }}>
                  Business Visitor Tracker
                </Typography>
              </Box>
            </CustomCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CustomCard>
              <Box
                p={2}
                className="dashboard_box"
                sx={{
                  height: "50%",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <div className="launchingsoon_logo"></div>
              </Box>
              <Box height="50%" pl={2} pr={2} pb={2}>
                <Typography sx={{ fontWeight: "700", fontSize: "18px" }}>
                  Launching Soon
                </Typography>
              </Box>
            </CustomCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CustomCard>
              <Box
                className="dashboard_box"
                p={2}
                sx={{
                  height: "50%",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <div className="launchingsoon_logo"></div>
                {/* <Ginger_colorIcon sx={{ width: "40px", height: "40px" }} /> */}
              </Box>
              <Box height="50%" pl={2} pr={2} pb={2}>
                <Typography sx={{ fontWeight: "700", fontSize: "18px" }}>
                  Launching Soon
                </Typography>
              </Box>
            </CustomCard>
          </Grid>
          {/* ))} */}
        </CardBox>
      </Box>
    </Box>
  );
}

export default CardContainer;
