import API from "../config/axios";

/**
 * Description : Edit label name or status
 * @param {*} labelId
 * @param {*} status
 * @param {*} labelname
 * @returns
 */

export const editLabel = async (labelId, status, labelname) => {
   try {
      const data = {
         status: status || null,
         labelName: labelname,
      };

      const response = await API.put(`/label/${labelId}`, data);
      return response.data;
   } catch (err) {
      console.log(err);
   }
};

/**
 * Description :Delete label with specific labelId
 * @param {*} labelId
 * @returns
 */
export const deleteLabel = async (labelId) => {
   try {
      const response = await API.delete(`/label/${labelId}`);
      return response.data;
   } catch (err) {
      console.log(err);
      return err;
   }
};

/**
 * Description :Assign label to the contact
 * @param {*} chatId
 * @param {*} labelId
 * @returns
 */
export const assignLabel = async (chatId, labelId) => {
   try {
      const response = await API.patch(`/contacts/add-label/${chatId}`, {
         labelId,
      });
      return response.data;
   } catch (err) {
      console.log(err);
      return err;
   }
};

/**
 * Description :Create new labels
 * @param {*} labelName
 * @returns
 */
export const createLabels = async (labelName, color) => {
   try {
      const data = {
         labelName: labelName,
         color,
      };
      const response = await API.post("/label", data);
      return response.data;
   } catch (err) {
      console.log(err);
      return err;
   }
};
