import React from "react";
import Header from "./Header";
import CardContainer from "./CardContainer";
import { Box, useMediaQuery } from "@mui/material";

function ContentContainer({ toggleDrawer, user }) {
  const matches = useMediaQuery("(min-width:900px)");
  return (
    <Box
      sx={{
        background: "#e0e3e7",
        // "linear-gradient(90deg, rgba(50,49,48,1) 19%, rgba(93,94,63,1) 52%, rgba(23,82,130,1) 100%)",
      }}
      px={matches ? 7 : 5}
    >
      <Box sx={{ bgcolor: "#eaeae",position:"relative" }}>
        <Header toggleDrawer={toggleDrawer} user={user} />
        <CardContainer />
      </Box>
    
    </Box>
  );
}

export default ContentContainer;
