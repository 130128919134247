import { Avatar, Box, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TimeIcon from "../../assets/icons/svg/time.svg";
import ReplyIcon from "@mui/icons-material/Reply";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import {
   getAllMessagesByChatId,
   resetMessageState,
   saveSelectedChat,
   setClicked,
   setMessageEmpty,
   setNewMessageEmpty,
} from "../../store/slices/messageSlice";
import { getAllChats } from "../../store/slices/chatSlice";
import { parseText } from "../../constant/helperFunctions";

const CustomBox = styled(Box)(({ dynamicColor }) => ({
   height: "80px",
   marigin: "15px",
   borderTop: "1px solid #80808047",
   display: "flex",
   cursor: "pointer",
   borderLeft: `5px solid ${dynamicColor}`,
}));

function ChatList({ chatData, newChat }) {
   const dispatch = useDispatch();
   const [lastMessageText, setLastMessageText] = useState("");
   const lastMessage = chatData?.chat?.messages?.message?.text || "";

   // useEffect(() => {
   //    if (lastMessage) {
   //       const trimmedText =
   //          lastMessage.length > 30
   //             ? lastMessage.substring(0, 30 - 3) + "..."
   //             : lastMessage;

   //       setLastMessageText(trimmedText);
   //    }
   // }, []);
   const date = moment(chatData?.chat?.last_message_at).format("L");
   const { chat } = chatData || {};

   const handleRemoveChat = (event) => {
      event.stopPropagation();
   };
   const handleGetAllMessagesByChatId = async (chatId, chatData) => {
      dispatch(resetMessageState());
      // if (chatId === "new") {
      //    dispatch(
      //       saveSelectedChat({
      //          _id: "new",
      //          phone_number: chatData.phone_number,
      //       })
      //    );
      //    dispatch(setMessageEmpty());

      //    return;
      // }
      // dispatch(setNewMessageEmpty());
      // dispatch(setLoadedMessageEmpty());
      await dispatch(getAllMessagesByChatId({ chatId, page: 1 }));
      await dispatch(getAllChats());
      dispatch(saveSelectedChat(chatData));

      // dispatch(removeNotification(chatId));
   };

   return (
      <CustomBox
         dynamicColor={chat.label?.color}
         onClick={() => handleGetAllMessagesByChatId(chat?._id, chat)}
      >
         <Box p={2} display="flex" alignItems="center">
            <Avatar
               sx={{
                  backgroundColor: "black",
                  color: "white",
                  width: "45px",
                  height: "45px",
               }}
            />
         </Box>
         <Box
            sx={{
               display: "flex",
               flexDirection: "column",
               justifyContent: "center",
               paddingRight: "20px",
               width: "100%",
            }}
         >
            <Typography fontSize="16px" fontWeight="600">
               {chat?.name}
            </Typography>
            <Typography
               fontSize={chat?.name ? "13px" : "16px"}
               fontWeight="600"
            >
               {chat?.phone_number}
            </Typography>
            {chat?._id !== "new" && (
               <Typography color="#999" fontSize="13px" fontWeight="400">
                  {parseText(lastMessageText)}
               </Typography>
            )}
            {/* {chat?._id === "new" && <Button onClick={handleRemoveChat}>Close</Button>} */}
         </Box>
         <Box
            sx={{
               width: "80px",
               display: "flex",
               padding: "10px",
               flexDirection: "column",
               justifyContent: "center",
            }}
         >
            {/* <Box
          sx={{
            height: "18px",
            width: "41px",
            borderRadius: "15px",
            backgroundColor: "#FFD66D",
            display: "flex",

            alignItems: "center",
          }}
        >
          <img src={TimeIcon} alt="logo" />
          <span style={{ fontSize: "10px", fontWeight: "500", color: "#fff" }}>
            2h
          </span>
        </Box> */}
            {chatData?.chat?.last_message_at && (
               <Typography color="#999" fontSize="10px" fontWeight="400" mt={2}>
                  {date}
               </Typography>
            )}
            {/* {chatData?.messageCount !== 0 && (
          <Box
            sx={{
              height: "20px",
              width: "20px",
              borderRadius: "50%",
              backgroundColor: "#1fa855",
              color: "#fff",
              fontWeight: "500",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {chatData?.messageCount}
          </Box>
        )} */}

            <Box display="flex" mt={2}>
               {chatData?.messageCount && chatData?.messageCount !== 0 ? (
                  <Avatar
                     sx={{
                        marginLeft: "10px",
                        height: "18px",
                        width: "18px",
                        backgroundColor: "#1fa855",
                        fontSize: "10px",
                     }}
                  >
                     {chatData?.messageCount}
                  </Avatar>
               ) : (
                  ""
               )}
            </Box>
         </Box>
      </CustomBox>
   );
}

export default ChatList;
