import {
  Avatar,
  Badge,
  Box,
  IconButton,
  SvgIcon,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import AlbumOutlinedIcon from "@mui/icons-material/AlbumOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Header({ user }) {
  const matches = useMediaQuery("(min-width:900px)");

  return (
    <Box
      py={1}
      sx={{
        display: "flex",
        position: "sticky",
        width: "100%",
        top: 0,
        justifyContent: "space-between",
        borderBottom: "1px solid #d5d5d5",
        height: "66px",
        backgroundColor: "#e0e3e7",
        zIndex: "999",
      }}
    >
    
    </Box>
  );
}

export default Header;
