import {
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   FormControl,
   FormControlLabel,
   InputLabel,
   ListItemIcon,
   ListItemText,
   MenuItem,
   Select,
   Typography,
   styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { assignLabel } from "../../../services/labelServide";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import { getAllChats } from "../../../store/slices/chatSlice";
import { useDispatch, useSelector } from "react-redux";
import { getAllLabels } from "../../../store/slices/labelSlice";
import toast from "react-hot-toast";
import { saveSelectedChat } from "../../../store/slices/messageSlice";

const CustomMenuItem = styled(MenuItem)({
   backgroundColor: "#f5f5f5",
   padding: "8px 16px" /* Adjust padding for spacing */,
   borderRadius: "4px" /* Add rounded corners */,
   "& .MuiListItemIcon-root": {
      minWidth: "32px" /* Adjust icon width */,
      display: "flex",
      justifyContent: "flex-end" /* Align icon to the right */,
   },

   "& .MuiListItemIcon-root svg": {
      color: "#999" /* Set icon color (gray) */,
   },
   "& .MuiListItemText-primary": {
      color: "#333" /* Set text color (dark gray) */,
      fontWeight: "500" /* Make text slightly bolder */,
   },
});

function AssignLabelModal({ openAddLabel, setopenAddLabel, selectedChat }) {
   const [label, setLabel] = useState(selectedChat?.label?._id || "");
   const { labels } = useSelector((state) => state.labels);

   const dispatch = useDispatch();

   const handleLabelChange = (e) => {
      setLabel(e.target.value);
   };

   const handleSave = async () => {
      const response = await assignLabel(selectedChat?._id, label);
      if (response.status === "success") {
         toast.success("Updated successfully");
         dispatch(saveSelectedChat(response?.data));
         setopenAddLabel(false);
         dispatch(getAllChats());
      }
   };

   const getLabels = () => {
      dispatch(getAllLabels());
   };

   useEffect(() => {
      getLabels();
   }, []);
   const handleClose = () => setopenAddLabel(false);

   return (
      <Dialog maxWidth="sm" open={openAddLabel} onClose={handleClose}>
         <DialogTitle>Assign Label</DialogTitle>
         <DialogContent>
            <DialogContentText>
               You can assign a labe for this contact to catogorize.
            </DialogContentText>
            <Box
               noValidate
               component="form"
               sx={{
                  display: "flex",
                  flexDirection: "column",
                  m: "auto",
                  width: "100%",
               }}
            >
               <Typography>{selectedChat?.phone_number}</Typography>
               <Typography>{selectedChat?.Name}</Typography>
               <FormControl sx={{ mt: 2 }} fullWidth>
                  <InputLabel htmlFor="max-width">Label</InputLabel>
                  <Select
                     autoFocus
                     value={label}
                     fullWidth
                     onChange={handleLabelChange}
                     label="maxWidth"
                     inputProps={{
                        name: "max-width",
                        id: "max-width",
                     }}
                  >
                     <MenuItem value="null">None</MenuItem>
                     {labels?.map((label) => (
                        <CustomMenuItem value={label?._id} key={label?._id}>
                           {label?.labelCategory}
                        </CustomMenuItem>
                     ))}
                  </Select>
               </FormControl>
            </Box>
         </DialogContent>
         <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            <Button onClick={handleSave}>Save</Button>
         </DialogActions>
      </Dialog>
   );
}

export default AssignLabelModal;
